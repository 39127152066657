import React, { useEffect, useState } from "react";
import axios from "axios";
import DynamicTable from "components/Admin/DynamicTable";

const config = {
	headerTitle: "Products",
	listDescription: "Here is the Products List",
	addButtonLabel: "Add Product",
	addButtonLink: "/admin/product/add",
	editButtonLink: "/admin/product/edit",
	tableHeaders: ["#", "Name", "Category Name", "Part Number", "Action"],
	tableFields: ["id", "name", "categoryName", "partNumber", "action"],
	search: {
		placeholder: "Search Products...",
	},
};

function Products() {
	const [products, setProducts] = useState([]);
	const [paginationData, setPaginationData] = useState({});
	const [searchTerm, setSearchTerm] = useState("");

	// Fetch products from the API
	const fetchProducts = async (page = 1, search = "") => {
		try {
			const response = await axios.get(`${process.env.REACT_APP_API_URL}admin/products`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
				params: { page, search },
			});
			const data = response.data;

			if (data.status) {
				setPaginationData(data.paginationData);
				setProducts(data.data);
			} else {
				console.error("Unexpected response format or status is false:", data);
			}
		} catch (error) {
			console.error("Error fetching products:", error);
			setProducts([]);
		}
	};

	useEffect(() => {
		fetchProducts();
	}, []);

	const onPageChange = async (page) => {
		await fetchProducts(page, searchTerm);
	};

	const handleSearchChange = (event) => {
		const value = event.target.value;
		setSearchTerm(value);
		fetchProducts(1, value);
	};

	const handleDelete = async (id) => {
		const confirmed = window.confirm("Are you sure you want to delete this product?");
		if (!confirmed) return;

		try {
			await axios.delete(`${process.env.REACT_APP_API_URL}admin/product/delete/${id}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			});
			setProducts((prevProducts) => prevProducts.filter((product) => product._id !== id));
		} catch (error) {
			console.error("Error deleting product:", error);
		}
	};

	const tableData = products.map((product, index) => ({
		id: (paginationData.page - 1) * paginationData.limit + (index + 1),
		name: product.name,
		categoryName: product.categoryName,
		partNumber: product.partNumber,
		action: product._id,
	}));

	return (
		<DynamicTable
			config={config}
			tableData={tableData}
			handleDelete={handleDelete}
			paginationData={paginationData}
			onPageChange={onPageChange}
			{...(config.search && { onSearchChange: handleSearchChange })}
		/>
	);
}

export default Products;
