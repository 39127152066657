import React from 'react';
import {
	CButton,
	CCol,
	CContainer,
	CRow,
} from '@coreui/react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
	const navigate = useNavigate();

	const handleGoBack = () => {
		navigate(-1);
	};

	return (
		<div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
			<CContainer>
			<CRow className="justify-content-center">
				<CCol md={6}>
				<div className="clearfix">
					<h1 className="float-start display-3 me-4">404</h1>
					<h4 className="pt-3">Oops! You’re lost.</h4>
					<p className="text-body-secondary float-start">
					The page you are looking for was not found.
					</p>
				</div>
				<CButton className="float-end" color="primary" onClick={handleGoBack}>
					Go Back
				</CButton>
				</CCol>
			</CRow>
			</CContainer>
		</div>
	);
};

export default NotFound;
