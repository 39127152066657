import React, { useState, useEffect, useCallback } from 'react';
import toast from 'react-hot-toast';
import FilePreview from 'components/Admin/FilePreview';

const HomeHeader = () => {
	const [loading, setLoading] = useState(false);
	const [files, setFiles] = useState({
		headingImage: null,
		aboutTwoFile: null,
		featuredProjectTopRightFile: null,
		featuredProjectBottomRightFile: null,
		featuredProjectCenterFile: null,
		featuredProjectTopLeftFile: null,
		featuredProjectBottomLeftFile: null,
	});
	const [setting, setSetting] = useState({
		heading: '',
		subHeading: '',
		youtubeUrl: '',
		aboutTwoHeading: '',
		aboutTwoDetail: '',
		featuredProjectTopRightText: '',
		featuredProjectBottomRightText: '',
		featuredProjectCenterText: '',
		featuredProjectTopLeftText: '',
		featuredProjectBottomLeftText: '',
		sideFixedButton: '',
		sideFixedButtonUrl: ''
	});

	const fetchSettingDetail = useCallback(async () => {
		try {
			const token = localStorage.getItem('token');
			const response = await fetch(`${process.env.REACT_APP_API_URL}admin/setting/headers/home-page`, {
				headers: { Authorization: `Bearer ${token}` },
			});
			const data = await response.json();
			if (data.status) {
				setSetting(data.data);
				setFiles({
					headingImage: data.data.headingImage,
					aboutTwoFile: data.data.aboutTwoFile,
					featuredProjectTopRightFile: data.data.featuredProjectTopRightFile,
					featuredProjectBottomRightFile: data.data.featuredProjectBottomRightFile,
					featuredProjectCenterFile: data.data.featuredProjectCenterFile,
					featuredProjectTopLeftFile: data.data.featuredProjectTopLeftFile,
					featuredProjectBottomLeftFile: data.data.featuredProjectBottomLeftFile,
				});
			} else {
				toast.error('Failed to fetch setting details.', { position: 'top-right' });
			}
		} catch (error) {
			console.error('Error fetching setting:', error);
			toast.error('Error fetching setting details.', { position: 'top-right' });
		}
	}, []);

	const saveSetting = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			const formData = new FormData();
			formData.append('type', 'home-page');

			// Append all setting fields to FormData
			Object.entries(setting).forEach(([key, value]) => {
				formData.append(key, value);
			});

			// Append file fields to FormData
			Object.entries(files).forEach(([key, value]) => {
				if (value) {
					formData.append(key, value);
				}
			});

			const token = localStorage.getItem('token');
			const response = await fetch(`${process.env.REACT_APP_API_URL}admin/setting/headers/store`, {
				method: 'POST',
				headers: { Authorization: `Bearer ${token}` },
				body: formData,
			});

			const result = await response.json();
			if (result.status) {
				let settings = JSON.parse(localStorage.getItem('settings'));
				if (settings) {
					localStorage.removeItem('settings');
				}
				toast.success('Setting saved successfully!', { position: 'top-right' });
			} else {
				toast.error('Failed to save setting.', { position: 'top-right' });
			}
		} catch (error) {
			console.error('Error saving setting:', error);
			toast.error('Failed to save setting.', { position: 'top-right' });
		} finally {
			setLoading(false); 
		}
	};

	useEffect(() => {
		fetchSettingDetail();
	}, [fetchSettingDetail]);

	const handleChange = (e) => {
		setSetting((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	const handleFileChange = (name) => (e) => {
		setFiles((prev) => ({ ...prev, [name]: e.target.files[0] }));
	};

	return (
		<div className='container'>
			<h2>Home Page Setting</h2>
			<form onSubmit={saveSetting}>
				<div className="mb-3">
					<label htmlFor="heading" className="form-label">Heading</label>
					<input
						type="text"
						name="heading"
						value={setting.heading}
						onChange={handleChange}
						className="form-control"
						id="heading"
						required
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="subHeading" className="form-label">Sub Heading</label>
					<textarea
						name="subHeading"
						value={setting.subHeading}
						onChange={handleChange}
						className="form-control"
						id="subHeading"
						rows={6}
						required
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="headingImage" className="form-label">Heading Image</label>
					<input type="file" name="headingImage" onChange={handleFileChange('headingImage')} className="form-control" />
					{files.headingImage && <FilePreview path={files.headingImage} />}
				</div>
				<div className="mb-3">
					<label htmlFor="youtubeUrl" className="form-label">Youtube Url</label>
					<input
						type="url"
						name="youtubeUrl"
						value={setting.youtubeUrl}
						onChange={handleChange}
						className="form-control"
						id="youtubeUrl"
						required
					/>
				</div>
				<br></br>
				{/* About Two Section */}
				<h4>Home About Section Two</h4>
				<div className="mb-3">
					<label htmlFor="aboutTwoHeading" className="form-label">About Heading</label>
					<input
						type="text"
						name="aboutTwoHeading"
						value={setting.aboutTwoHeading}
						onChange={handleChange}
						className="form-control"
						id="aboutTwoHeading"
						required
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="aboutTwoDetail" className="form-label">About Detail</label>
					<textarea
						name="aboutTwoDetail"
						value={setting.aboutTwoDetail}
						onChange={handleChange}
						className="form-control"
						id="aboutTwoDetail"
						rows={6}
						required
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="aboutTwoFile" className="form-label">About File</label>
					<input type="file" name="aboutTwoFile" onChange={handleFileChange('aboutTwoFile')} className="form-control" />
					{files.aboutTwoFile && <FilePreview path={files.aboutTwoFile} />}
				</div>

				<br></br>
				{/* Featured Project Files */}
				<h4>Featured Projects</h4>
				{['Top Right', 'Bottom Right', 'Center', 'Top Left', 'Bottom Left'].map((position) => {
					const positionKey = `featuredProject${position.replace(' ', '')}File`;
					const urlKey = `featuredProject${position.replace(' ', '')}Text`;

					return (
						<div className="mb-3" key={position}>
							<label htmlFor={`${positionKey}`} className="form-label">{position} File</label>
							<input
								type="file"
								name={positionKey}
								onChange={handleFileChange(positionKey)}
								className="form-control"
							/>
							{files[positionKey] && <FilePreview path={files[positionKey]} />}
							<label htmlFor={`${urlKey}`} className="form-label">{position} Text</label>
							<input
								type="text"
								name={urlKey}
								value={setting[urlKey]}
								onChange={handleChange}
								className="form-control"
								id={urlKey}
							/>
							<br></br>
							<br></br>
						</div>
					);
				})}

				<h4>Additional</h4>
				<div className="mb-3">
					<label htmlFor="sideFixedButton" className="form-label">Side Fixed Button</label>
					<input
						type="text"
						name="sideFixedButton"
						value={setting.sideFixedButton}
						onChange={handleChange}
						className="form-control"
						id="sideFixedButton"
						required
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="sideFixedButtonUrl" className="form-label">Side Fixed Button Url</label>
					<input
						type="text"
						name="sideFixedButtonUrl"
						value={setting.sideFixedButtonUrl}
						onChange={handleChange}
						className="form-control"
						id="sideFixedButtonUrl"
						required
					/>
				</div>
				<br></br>
				<br></br>
				<button type="submit" className="btn btn-primary mb-3" disabled={loading}>
					{loading ? 'Saving...' : 'Save Settings'}
				</button>
			</form>
		</div>
	);
};

export default HomeHeader;
