import React, { useState, useEffect, useCallback } from 'react';
import toast from 'react-hot-toast';
import FilePreview from 'components/Admin/FilePreview';
import TinyMCEInput  from 'components/TinyMCEInput';

const AboutHeader = () => {
	const [file, setFile] = useState(null);
	const token = localStorage.getItem('token');
	const [editingAccordion, setEditingAccordion] = useState(null);
	const [setting, setSetting] = useState({ heading: '', subHeading: '', headingImage: '' });
	const [sectionTwo, setSectionTwo] = useState({ sectionTwoHeading: '', sectionTwoSubHeading: '', sectionTwoDescription: '' });
	const [sectionThree, setSectionThree] = useState({ sectionThreeHeading: '', sectionThreeDescription: '', YoutubeUrl: '' });
	const [accordionItems, setAccordionItems] = useState([{ id: '1', title: '', content: '', description: '' }]);

	// Fetch settings data for the about page header
	const fetchSettingDetail = useCallback(async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}admin/setting/headers/about-page`, {
				headers: { Authorization: `Bearer ${token}` },
			});
			const data = await response.json();
			if (data.status) {
				setSetting(data.data);
				setSectionTwo(data.data);
				setSectionThree(data.data);
			} else {
				toast.error('Failed to fetch setting details.', { position: 'top-right' });
			}
		} catch (error) {
			console.error('Error fetching setting:', error);
			toast.error('Error fetching setting details.', { position: 'top-right' });
		}
	}, [token]);

	const fetchAccordionDetail = useCallback(async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}admin/setting/about-accordion/get`, {
				headers: { Authorization: `Bearer ${token}` },
			});
			const data = await response.json();
			if (data.status) {
				setAccordionItems(Array.isArray(data.data) ? data.data : []);
			} else {
				toast.error('Failed to fetch setting details.', { position: 'top-right' });
			}
		} catch (error) {
			console.error('Error fetching accordion:', error);
			toast.error('Error fetching accordion details.', { position: 'top-right' });
		}
	}, [token]);

	// Call fetchSettingDetail once on component mount
	useEffect(() => {
		fetchSettingDetail();
		fetchAccordionDetail();
	}, [fetchSettingDetail, fetchAccordionDetail]);

	// Handle changes to form inputs
	const handleChange = (e) => {
		setSetting((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	// Handle file input change
	const handleFile = (e) => {
		setFile(e.target.files[0]);
	};

	const handleAccordionChange = (e) => {
		setEditingAccordion((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	// Handle form submission
	const saveSetting = async (e) => {
		e.preventDefault();
		try {
			const formData = new FormData();
			Object.entries(setting).forEach(([key, value]) => {
				if (value) {
					formData.append(key, value);
				}
			});
			if (file) formData.append('headingImage', file);

			const response = await fetch(`${process.env.REACT_APP_API_URL}admin/setting/headers/store`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formData,
			});

			const result = await response.json();

			if (result.status) {
				let settings = JSON.parse(localStorage.getItem('settings'));
				if (settings) {
					localStorage.removeItem('settings');
				}
				toast.success('Setting saved successfully!', { position: 'top-right' });
			} else {
				toast.error('Failed to save setting.', { position: 'top-right' });
			}
		} catch (error) {
			console.error('Error saving setting:', error);
			toast.error('Failed to save setting.', { position: 'top-right' });
		}
	};

	const accordionSave = async (e) => {
		e.preventDefault();
		try {
			const accordionData = new FormData();
			Object.entries(editingAccordion).forEach(([key, value]) => {
				if (value) {
					accordionData.append(key, value);
				}
			});
			const responseAccordion = await fetch(`${process.env.REACT_APP_API_URL}admin/setting/about-accordion/store`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: accordionData,
			});
			const accordionResponse = await responseAccordion.json();
			if (accordionResponse.status) {
				let settings = JSON.parse(localStorage.getItem('settings'));
				if (settings) {
					localStorage.removeItem('settings');
				}
				toast.success('Accordion saved successfully!', { position: 'top-right' });
				fetchAccordionDetail(); // Refresh accordion list
				setEditingAccordion(null); // Reset editing state
			} else {
				toast.error('Failed to save accordion.', { position: 'top-right' });
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleAction = async (action, id) => {
		if (action === 'edit') {
			const accordionItemToEdit = accordionItems.find((item) => item._id === id);
			setEditingAccordion(accordionItemToEdit);
		} else if (action === 'delete') {
			if (window.confirm(`Are you sure you want to delete the accordion with ID: ${id}?`)) {
				try {
					const deleteResponse = await fetch(`${process.env.REACT_APP_API_URL}admin/setting/about-accordion/delete/${id}`, {
						method: 'DELETE',
						headers: {
							Authorization: `Bearer ${token}`,
							'Content-Type': 'application/json',
						},
					});

					const deleteData = await deleteResponse.json();
					if (deleteData.status) {
						fetchAccordionDetail(); // Refresh accordion list
					} else {
						toast.error('Failed to delete the accordion.', { position: 'top-right' });
					}
				} catch (error) {
					console.log(error);
					toast.error('An error occurred while deleting the accordion.', { position: 'top-right' });
				}
			} else {
				console.log('Deletion canceled');
			}
		}
	};

	const handleSectionTwoChange = async(e) => {
		setSectionTwo((prev) => ({ ...prev, [e.target.name]: e.target.value}));
	}

	const sectionTwoSubmit = async (e) => {
		e.preventDefault();
	
		try {
			const formData = new FormData();
			formData.append('sectionTwoHeading', sectionTwo.sectionTwoHeading);
			formData.append('sectionTwoSubHeading', sectionTwo.sectionTwoSubHeading);
			formData.append('sectionTwoDescription', sectionTwo.sectionTwoDescription);
	
			const response = await fetch(`${process.env.REACT_APP_API_URL}admin/setting/about-section-two/store`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formData,
			});

			const result = await response.json();
			if (result.status) {
				let settings = JSON.parse(localStorage.getItem('settings'));
				if (settings) {
					localStorage.removeItem('settings');
				}
				toast.success('Setting saved successfully!', { position: 'top-right' });
			} else {
				toast.error('Failed to save setting.', { position: 'top-right' });
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleSectionThreeChange = async(e) => {
		setSectionThree((prev) => ({ ...prev, [e.target.name]: e.target.value}));
	}

	const sectionThreeSubmit = async (e) => {
		e.preventDefault();
	
		try {
			const formData = new FormData();
			formData.append('sectionThreeHeading', sectionThree.sectionThreeHeading);
			formData.append('sectionThreeDescription', sectionThree.sectionThreeDescription);
			formData.append('YoutubeUrl', sectionThree.YoutubeUrl);
	
			const response = await fetch(`${process.env.REACT_APP_API_URL}admin/setting/about-section-three/store`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formData,
			});

			const result = await response.json();
			if (result.status) {
				let settings = JSON.parse(localStorage.getItem('settings'));
				if (settings) {
					localStorage.removeItem('settings');
				}
				toast.success('Setting saved successfully!', { position: 'top-right' });
			} else {
				toast.error('Failed to save setting.', { position: 'top-right' });
			}
		} catch (error) {
			console.log(error);
		}
	};

	const inputHandlerCk = (field, value) => {
		setSectionThree((prev) => ({
			...prev,
			[field]: value || '', // Ensure value is not null
		}));
	};

	return (
		<div className='container'>
			<h2>About Page Setting</h2>
			<form onSubmit={saveSetting}>
				<div className="mb-3">
					<label htmlFor="heading" className="form-label">Heading</label>
					<input
						type="text"
						name="heading"
						value={setting.heading}
						onChange={handleChange}
						className="form-control"
						id="heading"
						required
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="subHeading" className="form-label">Sub Heading</label>
					<textarea
						name="subHeading"
						value={setting.subHeading}
						onChange={handleChange}
						className="form-control"
						id="subHeading"
						rows={6}
						required
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="headingImage" className="form-label">File</label>
					<input type="file" name="headingImage" onChange={handleFile} className="form-control" />
					{setting.headingImage && <FilePreview path={setting.headingImage} />}
				</div>
				<button type="submit" className="btn btn-primary mb-3">Save</button>
			</form>
			<br />
			<hr />
			<br />

			<h2>Section 2</h2>
			<form onSubmit={sectionTwoSubmit}>
				<div className="mb-3">
					<label htmlFor="sectionTwoHeading" className="form-label">Heading</label>
					<input
						type="text"
						name="sectionTwoHeading"
						value={sectionTwo.sectionTwoHeading}
						onChange={handleSectionTwoChange}
						className="form-control"
						id="sectionTwoHeading"
						required
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="sectionTwoSubHeading" className="form-label">Sub heading</label>
					<input
						type="text"
						name="sectionTwoSubHeading"
						value={sectionTwo.sectionTwoSubHeading}
						onChange={handleSectionTwoChange}
						className="form-control"
						id="sectionTwoSubHeading"
						required
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="sectionTwoDescription" className="form-label">Description</label>
					<textarea
						name="sectionTwoDescription"
						value={sectionTwo.sectionTwoDescription}
						onChange={handleSectionTwoChange}
						className="form-control"
						id="sectionTwoDescription"
						rows={6}
						required
					/>
				</div>
				<button type="submit" className="btn btn-primary mb-3">Save</button>
			</form>
			<br />
			<hr />
			<br />

			<h2>Section 3</h2>
			<form onSubmit={sectionThreeSubmit}>
				<div className="mb-3">
					<label htmlFor="sectionThreeHeading" className="form-label">Heading</label>
					<input
						type="text"
						name="sectionThreeHeading"
						value={sectionThree.sectionThreeHeading}
						onChange={handleSectionThreeChange}
						className="form-control"
						id="sectionThreeHeading"
						required
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="sectionThreeDescription" className="form-label">Description</label>
					<TinyMCEInput
						value={sectionThree.sectionThreeDescription || ''}
						field="sectionThreeDescription"
						inputHandler={inputHandlerCk}
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="youtubeUrl" className="form-label">Youtube Url</label>
					<input
						type="url"
						name="youtubeUrl"
						value={sectionThree.youtubeUrl}
						onChange={handleSectionThreeChange}
						className="form-control"
						id="youtubeUrl"
						required
					/>
				</div>
				<button type="submit" className="btn btn-primary mb-3">Save</button>
			</form>
			<br />
			<hr />
			<br />

			<form onSubmit={accordionSave}>
				<h2>About Accordion Glance</h2>
				<input type='hidden' name='id' value={editingAccordion ? editingAccordion._id : null} />
				<div className="mb-3">
					<label htmlFor="title" className="form-label">Title</label>
					<input
						name="title"
						value={editingAccordion ? editingAccordion.title : ''}
						onChange={handleAccordionChange}
						className="form-control"
						id="title"
						required
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="content" className="form-label">Content</label>
					<textarea
						name="content"
						value={editingAccordion ? editingAccordion.content : ''}
						onChange={handleAccordionChange}
						className="form-control"
						id="content"
						rows={6}
						required
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="description" className="form-label">Description</label>
					<textarea
						name="description"
						value={editingAccordion ? editingAccordion.description : ''}
						onChange={handleAccordionChange}
						className="form-control"
						id="description"
						rows={6}
						required
					/>
				</div>
				<button type="submit" className="btn btn-primary mb-3"
				// disabled={accordionItems.length >= 10}
				>Save Accordion</button>
			</form>

			<div className="table-responsive">
				<table className="table table-striped">
					<thead>
						<tr>
							<th>Id</th>
							<th>Title</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						{accordionItems.length > 0 ? (
							accordionItems.map((item, index) => (
								<tr key={item._id}>
									<td>{index + 1}</td>
									<td>{item.title}</td>
									<td>
										<button
											className="btn btn-primary mx-4"
											onClick={() => handleAction('edit', item._id)}
										>
											Edit
										</button>
										<button
											className="btn btn-primary" 
											onClick={() => handleAction('delete', item._id)}
										>
											Delete
										</button>
									</td>
								</tr>
							))
						) : (
							<tr>
								<td colSpan="3" className="text-center">
									No accordion present
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default AboutHeader;
