import React, { useState, useEffect } from 'react';
import Navbar from 'components/Navbar';
import Header from 'components/Header';
import Footer from 'components/Footer';
import CategoryCard from 'components/HomeSections/CategoryCard';
import SubscribeButton  from 'components/SubscribeButton';
// import HeaderImage from 'assets/images/background-image/about-bg.webp';

function Category() {
	const [categories, setCategories] = useState([]);

	useEffect(() => {
		window.scrollTo(0, 0);
		const fetchCategories = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}guest/category`);
				const data = await response.json();
				setCategories(data.data);
			} catch (error) {
				console.error("Error fetching categories:", error);
			}
		};
		fetchCategories();
	}, []);
	return (
		<>
			<Navbar />
			<Header page='product_page' />
			<div className='container-fluid bg-black text-white'>
				<div className='container'>
					<div className='py-4'>
						<nav aria-label="breadcrumb">
							<ol className="guest-breadcrumb breadcrumb">
								 <li className="breadcrumb-item"><a href="/">Home</a></li>
								 <li className="breadcrumb-item"><a href="categories">Product Categories</a></li>
							</ol>
						</nav>
					</div>
					<h1 className='py-4'>Product Categories</h1>
					<div className='row'>
						{categories.map((category) => (
							<div className='col-md-4 mb-4' key={category._id}>
								<CategoryCard 
									image={category.filePath}
									name={category.categoryName}
									render={`/products/${category.categoryName}`}
								/>
							</div>
						))}
					</div>
				</div>
			</div>
			<Footer />
			<SubscribeButton />
		</>
	);
}

export default Category;