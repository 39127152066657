import React, { useState, useEffect } from 'react';
import Navbar from 'components/Navbar';
import Header from 'components/Header';
import SectionAbout from 'components/HomeSections/About';
import SectionProjects from 'components/HomeSections/Project';
import SectionCategoryList from 'components/HomeSections/Category';
import SectionFeaturedProducts from 'components/HomeSections/Product';
import SectionMap from 'components/HomeSections/Map';
import SectionNews from 'components/HomeSections/News';
import Footer from 'components/Footer';
import SubscribeButton  from 'components/SubscribeButton';
import CookiePopup from 'components/CookiePopup';

function Home() {
	const [showCookiePopup, setShowCookiePopup] = useState(false);

	useEffect(() => {
		const cookieAccepted = localStorage.getItem('cookieAccepted');
		if (!cookieAccepted) {
			setShowCookiePopup(true);
		}
	}, []);

	const handleAcceptCookies = () => {
		localStorage.setItem('cookieAccepted', 'true');
		setShowCookiePopup(false);
	};

	return (
		<>
			<Navbar />
			<Header page='home_page' />
			<SectionAbout />
			<SectionProjects />
			<SectionCategoryList />
			<SectionFeaturedProducts />
			<SectionMap />
			<SectionNews />
			<Footer />
			<SubscribeButton />
			{showCookiePopup && <CookiePopup onAccept={handleAcceptCookies} />}
		</>
	);
}

export default Home;
