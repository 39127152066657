import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { parseISO, formatDistanceToNow } from 'date-fns';
import Navbar from 'components/Navbar';
import Header from 'components/Header';
import Footer from 'components/Footer';
import CalenderIcon from 'assets/images/icons/calender.svg';
// import HeaderImage from 'assets/images/background-image/about-bg.webp';

const formatDate = (dateStr) => {
	if (!dateStr) return '';
	try {
		const dateParsed = parseISO(dateStr);
		return formatDistanceToNow(dateParsed, { addSuffix: true });
	} catch (error) {
		console.error('Date parsing error:', error);
		return '';
	}
};

const truncateDescription = (description, wordLimit = 15) => {
	if (!description)
		return null;
	const words = description.split(' ');
	if (words.length <= wordLimit)
		return description;
	return words.slice(0, wordLimit).join(' ') + '...';
};

function NewsDetail() {
	const { id } = useParams();
	const [newsDetail, setNewsDetail] = useState({});
	const [recentData, setRecentData] = useState([]);

	useEffect(() => {
		window.scrollTo(0, 0);
		const fetchNewsDetail = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}guest/news/detail/${id}`);
				const data1 = await response.json();
				setNewsDetail(data1.data);

				const recent = await fetch(`${process.env.REACT_APP_API_URL}guest/news/recent`);
				const data2 = await recent.json();
				setRecentData(data2.data);

			} catch (error) {
				console.error('News detail not found:', error);
			}
		};
		fetchNewsDetail();
	}, [id]);

	return (
		<>
			<Navbar />
			<Header page='news_page' />
			<div className='container-fluid bg-black text-white py-5'>
				<div className='container'>
					<div className='py-4'>
						<nav aria-label="breadcrumb">
							<ol className="guest-breadcrumb breadcrumb">
								<li className="breadcrumb-item"><a href="/">Home</a></li>
								<li className="breadcrumb-item"><a href="/news">News</a></li>
								<li className="breadcrumb-item"><a href="#">{newsDetail.title}</a></li>
							</ol>
						</nav>
					</div>
					<h1 className='mb-4'>{newsDetail.title}</h1>
					<div className='d-flex justify-content-between'>
						<div className='col-md-6 mb-4'>
							{newsDetail.imagePath && newsDetail.imagePath.length > 0 && (
								<img
									src={`${process.env.REACT_APP_BACKEND_URL}${newsDetail.imagePath[0]}`}
									alt={newsDetail.title}
									className='img-fluid rounded-4 w-100'
								/>
							)}
							<div className='d-flex justify-content-between align-items-center py-2'>
								<span>By Maxtron</span>
								<span>
									<img src={CalenderIcon} alt="Calendar Icon" width={25} />
									{formatDate(newsDetail.updatedAt)}
								</span>
							</div>
							<p className='py-2'>{newsDetail.description}</p>
						</div>
						<div className='col-md-5'>
							{recentData.length > 0 ? (
								<div className='bg-green p-4 rounded-4'>
									<h1 className='mb-3'>Recent Posts</h1>
									{recentData.map((recent) => (
										<Link key={recent._id} className='text-decoration-none text-white' to={`/news/detail/${recent._id}`}>
											<div className='mb-4'>
												<div className='d-flex flex-row justify-content-between align-items-start'>
													<div className='flex-shrink-0'>
														<img
															src={`${process.env.REACT_APP_BACKEND_URL}${recent.imagePath[0]}`}
															alt={recent.title}
															className='rounded-2 mb-2'
															width={185}
														/>
													</div>
													<div className='flex-grow-1 ms-3'>
														<div className='mb-1'>
															<h5>{recent.title}</h5>
															<p>{truncateDescription(recent.description, 10)}</p>
														</div>
														<div className='d-flex justify-content-between align-items-center'>
															<span>By Maxtron</span>
															<span>
																<img src={CalenderIcon} alt="Calendar Icon" width={18} />
																{formatDate(recent.createdAt)}
															</span>
														</div>
													</div>
												</div>
											</div>
										</Link>
									))}
								</div>
							) : (
								<div className='bg-green p-4 rounded-4'>
									<h1 className='mb-3'>Recent Posts</h1>
									<p>No recent posts available.</p>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}

export default NewsDetail;
