import Dashboard from 'views/admin/dashboard/Dashboard';

import ContactIndex from 'views/admin/contact/index';
import ContactEdit from 'views/admin/contact/edit';

import CategoryIndex from 'views/admin/categories/index';
import CategoryEdit from 'views/admin/categories/edit';

import InquiryIndex from 'views/admin/inquiries/index';
import InquiryEdit from 'views/admin/inquiries/edit';

import NewsIndex from 'views/admin/news/index';
import NewsEdit from 'views/admin/news/edit';

import ManualsIndex from 'views/admin/manuals/index';
import ManualsEdit from 'views/admin/manuals/edit';

import ProductIndex from 'views/admin/products/index';
import ProductEdit from 'views/admin/products/edit';

import CategoriesAttr from 'views/admin/categoriesAttr/index';
import CategoriesAttrEdit from 'views/admin/categoriesAttr/edit';

import ProjectIndex from 'views/admin/projects/index';
import ProjectEdit from 'views/admin/projects/edit';

import StaticPageIndex from 'views/admin/static/index';
import StaticPageEdit from 'views/admin/static/edit';

import SettingMeta from 'views/admin/settings/Meta';
import SettingHomeHeader from 'views/admin/settings/HomeSetting';
import SettingAboutHeader from 'views/admin/settings/AboutSetting';

// import UsersList from 'views/admin/users/index';
import UserProfile from 'views/admin/profile/index';

const routes = [
	{ path: '/admin/', exact: true, name: 'Home', element: Dashboard },
	{ path: '/admin/dashboard', name: 'Dashboard', element: Dashboard },

	{ path: '/admin/contact/index', name: 'Contacts', element: ContactIndex },
	{ path: '/admin/contact/edit/:id', name: 'Contact Edit', element: ContactEdit },
	{ path: '/admin/contact/add', name: 'Contact Add', element: ContactEdit },

	{ path: '/admin/category/index', name: 'Categories', element: CategoryIndex },
	{ path: '/admin/category/edit/:id', name: 'Category Edit', element: CategoryEdit },
	{ path: '/admin/category/add', name: 'Category Add', element: CategoryEdit },

	{ path: '/admin/inquiry/index', name: 'Inquiries', element: InquiryIndex },
	{ path: '/admin/inquiry/edit/:id', name: 'Inquiry Edit', element: InquiryEdit },
	{ path: '/admin/inquiry/add', name: 'Inquiry Add', element: InquiryEdit },

	{ path: '/admin/news/index', name: 'News', element: NewsIndex },
	{ path: '/admin/news/edit/:id', name: 'News Edit', element: NewsEdit },
	{ path: '/admin/news/add', name: 'News Add', element: NewsEdit },

	{ path: '/admin/manuals/index', name: 'Manuals', element: ManualsIndex },
	{ path: '/admin/manual/edit/:id', name: 'Manual Edit', element: ManualsEdit },
	{ path: '/admin/manual/add', name: 'Manual Add', element: ManualsEdit },

	{ path: '/admin/product/index', name: 'Products', element: ProductIndex },
	{ path: '/admin/product/edit/:id', name: 'Product Edit', element: ProductEdit },
	{ path: '/admin/product/add', name: 'Product Add', element: ProductEdit },
	
	{ path: '/admin/categories-attr/index', name: 'Categories Attr', element: CategoriesAttr },
	{ path: '/admin/categories-attr/edit/:id', name: 'Categories Attr Edit', element: CategoriesAttrEdit },
	{ path: '/admin/categories-attr/add', name: 'Categories Attr Add', element: CategoriesAttrEdit },

	{ path: '/admin/project/index', name: 'Projects', element: ProjectIndex },
	{ path: '/admin/project/edit/:id', name: 'Project Edit', element: ProjectEdit },
	{ path: '/admin/project/add', name: 'Project Add', element: ProjectEdit },

	{ path: '/admin/static-pages/index', name: 'Static Pages', element: StaticPageIndex },
	{ path: '/admin/static-page/edit/:id', name: 'Static Page Edit', element: StaticPageEdit },
	{ path: '/admin/static-page/add', name: 'Static Page Add', element: StaticPageEdit },

	{ path: '/admin/setting/index', name: 'Settings', element: SettingMeta },
	{ path: '/admin/setting/home-header', name: 'Home Content Settings', element: SettingHomeHeader },
	{ path: '/admin/setting/about-header', name: 'Home Content Settings', element: SettingAboutHeader },

	// { path: '/admin/users', name: 'Users', element: UsersList },

	{ path: '/admin/profile', name: 'Profile', element: UserProfile },
];

export default routes;
