import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from 'components/Navbar';
import Header from 'components/Header';
import Footer from 'components/Footer';
import SubscribeButton from 'components/SubscribeButton';
import ImageNotFound from 'assets/images/image_not_found.png';

function ProjectDetail() {
	const { id } = useParams();
	const [project, setProject] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);
	const [currentImage, setCurrentImage] = useState('');

	const fetchProject = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}guest/project/detail/${id}`);
			const data = await response.json();
			if (data?.data?.filePath?.length) {
				setCurrentImage(data.data.filePath[0]);
			}

			setProject(data.data);
			setLoading(false);
		} catch (error) {
			setError('Failed to load project details. Please try again later.');
			console.error('Error fetching project:', error);
			setLoading(false);
		}
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		fetchProject();
	}, [id]);

	const getFileSrc = (path) => {
		const isUrl = path.startsWith('http') || path.startsWith('https');
		return isUrl ? path : `${process.env.REACT_APP_BACKEND_URL}${path}`;
	};

	return (
		<>
			<Navbar />
			<Header page='project_page' />
			<div id="project-detail" className='container-fluid bg-black'>
				<div className='container text-white py-5'>
					{error && <div className='alert alert-danger'>{error}</div>}
					{loading ? (
						<p>Loading...</p>
					) : (
						project && (
							<div className='row'>
								<div className='col-lg-6'>
									<h1>{project.name}</h1>
									<div className='main-image-container'>
										<img
											src={currentImage ? getFileSrc(currentImage) : ImageNotFound}
											className="main-image rounded-4"
											alt="Current Project"
										/>
									</div>
									{project.filePath?.length > 1 && (
										<div className='thumbnail-container'>
											{project.filePath.map((path, index) => (
												<img
													key={index}
													src={getFileSrc(path)}
													className={`thumbnail ${currentImage === path ? 'active' : ''}`}
													alt={`Thumbnail ${index + 1}`}
													onClick={() => setCurrentImage(path)}
												/>
											))}
										</div>
									)}
								</div>
								<div className='col-lg-6'>
									<div className='text-white p-4'>
										<h6>Project Details</h6>
										<div className='table-container rounded-4 border border-white'>
											<table className='table table-bordered table-transparent'>
												<tbody>
													<tr>
														<th scope='row'>Project Name</th>
														<td>{project.name}</td>
													</tr>
													<tr>
														<th scope='row'>Location</th>
														<td>{project.location}</td>
													</tr>
													<tr>
														<th scope='row'>Category</th>
														<td>{project.category}</td>
													</tr>
													<tr>
														<th scope='row'>Owner Name</th>
														<td>{project.ownerName}</td>
													</tr>
													<tr>
														<th scope='row'>Module Type</th>
														<td>{project.moduleType}</td>
													</tr>
													<tr>
														<th scope='row'>System Version</th>
														<td>{project.systemVersion}</td>
													</tr>
													<tr>
														<th scope='row'>Year of Completion</th>
														<td>{project.yearOfCompletion}</td>
													</tr>
												</tbody>
											</table>
										</div>
										<p className='description'>{project.description}</p>
									</div>
								</div>
							</div>
						)
					)}
				</div>
			</div>
			<Footer />
			<SubscribeButton />
		</>
	);
}

export default ProjectDetail;
