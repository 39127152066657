import React from 'react';
import Pagination from 'components/Pagination';
import ProductCard from 'components/ProductCard';
import ProductCardRow from 'components/ProductCardRow';

const truncateDescription = (description, wordLimit = 15) => {
	if (!description)
		return null;
	const words = description.split(' ');
	if (words.length <= wordLimit)
		return description;
	return words.slice(0, wordLimit).join(' ') + '...';
};

const List = ({ view, products, paginationData, onPageChange }) => {
	const { totalItems, itemsPerPage, currentPage } = paginationData || {};

	return (
		<div className="content">
			{view === 'grid' ? (
				<div className="container">
					<div className="row">
						{products.map((product, index) => (
							<div className="col-12 col-sm-6 col-md-4 mb-4" key={product.id || index}>
								<div className="bg-green rounded-4">
									<ProductCard
										image={`${process.env.REACT_APP_BACKEND_URL}${product.imagePath[0]}`}
										name={product.name}
										partNumber={product.partNumber}
										description={truncateDescription(product.description, 10)}
										render={'/product/detail/'+product._id}
									/>
								</div>
							</div>
						))}
					</div>
				</div>
			) : (
				<div className="list-view">
					<div className="container">
						<div className="row">
							{products.map((product, index) => (
								<div className="col-12 mb-4" key={product.id || index}>
									<div>
										<ProductCardRow
											image={`${process.env.REACT_APP_BACKEND_URL}${product.imagePath[0]}`}
											name={product.name}
											partNumber={product.partNumber}
											render={'/product/detail/'+product._id}
											description={truncateDescription(product.description, 50)}
											date={product.date}
										/>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			)}
			{paginationData && (
				<Pagination
					totalItems={totalItems}
					itemsPerPage={itemsPerPage}
					currentPage={currentPage}
					onPageChange={onPageChange}
				/>
			)}
		</div>
	);
};

export default List;
