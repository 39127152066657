import axios from 'axios';
import toast from 'react-hot-toast';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FilePreview from 'components/Admin/FilePreview';
import TinyMCEInput  from 'components/TinyMCEInput';

function ProductEdit() {
	const { id } = useParams();
	const navigate = useNavigate();
	const [imageFiles, setImageFiles] = useState([]);
	const [categories, setCategories] = useState([]);
	const [manuals, setManuals] = useState([]);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [product, setProduct] = useState({
		categoryId: "",
		name: "",
		partNumber: "",
		description: "",
		specifications: "",
		imagePath: "",
		documentPath: "",
		isFeatured: false
	});

	useEffect(() => {
		const fetchProduct = async () => {
			if (id) {
				try {
					const response = await fetch(`${process.env.REACT_APP_API_URL}admin/product/detail/${id}`, { 
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						}
					});
					const data = await response.json();
					setProduct(data.data);
					if (data.data.imagePath && data.data.imagePath.length > 0) {
						setImageFiles([data.data.imagePath]);
					} else {
						setImageFiles([]);
					}
				} catch (error) {
					toast.error("Failed to fetch product details", { position: "top-right" });
				}
			}
		};
		const fetchCategories = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}admin/category`, { 
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				 });
				const data = await response.json();
				setCategories(data.data);
			} catch (error) {
				toast.error("Failed to fetch categories", { position: "top-right" });
			}
		};

		const fetchManuals = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}admin/manuals`, { 
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				});
				const data = await response.json();
				setManuals(data.data);
			} catch (error) {
				console.error('Manuals not found')
			}
		};
		
		fetchProduct();
		fetchManuals();
		fetchCategories();
	}, [id]);

	const inputHandler = (e) => {
		const { name, value, type, checked } = e.target;
		setProduct(prevProduct => ({
			...prevProduct,
			[name]: type === 'checkbox' ? checked : value,
		}));
	};

	const handleFileChange = (e) => {
		const { files } = e.target;
		setImageFiles(Array.from(files));
	};

	const saveProduct = async (e) => {
		e.preventDefault();
		setIsSubmitting(true);

		try {
			const formData = new FormData();

			imageFiles.forEach((file, index) => {
				formData.append('images', file);
			});

			Object.keys(product).forEach(key => {
				if (product[key] !== null && product[key] !== undefined && product[key] !== '') {
					formData.append(key, product[key]);
				}
			});

			const url = id
				? `${process.env.REACT_APP_API_URL}admin/product/update/${id}`
				: `${process.env.REACT_APP_API_URL}admin/product/create`;

			const response = await axios({
				method: id ? 'put' : 'post',
				url,
				data: formData,
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
					'Content-Type': 'multipart/form-data'
				}
			});
			toast.success(response.data.message, { position: "top-right" });
			navigate("/admin/product/index");
		} catch (error) {
			if (error.response) {
				setIsSubmitting(false);
				toast.error(error.message, { position: "top-right" });
			}
		}
	};

	const handleRemoveImage = async (index, productId) => {
		if (index !== null && productId !== null) {
			const confirmed = window.confirm('Are you sure you want to delete this?');

			if (confirmed) {
				try {
					const response = await fetch(`${process.env.REACT_APP_API_URL}admin/product/${productId}/image/${index}`, {
						method: 'DELETE',
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.ok) {
						console.log('Image deleted successfully.');
						toast.success('Image deleted successfully', { position: "top-right" });
						setProduct((prevProduct) => ({
							...prevProduct,
							imagePath: prevProduct.imagePath.filter((_, i) => i !== index),
						}));
					} else {
						console.log('Failed to delete the image.');
					}
				} catch (error) {
					console.error('Error deleting image:', error);
				}
			}
		}
	};

	const inputHandlerCk = (field, value) => {
        setProduct((prevProduct) => ({
            ...prevProduct,
            [field]: value,
        }));
    };

	return (
		<div className='container'>
			<h1>{id ? 'Edit Product' : 'Add Product'}</h1>
			<form onSubmit={saveProduct}>
				<div className="mb-3">
					<label htmlFor="categoryId" className="form-label">Category</label>
					<select
						name="categoryId"
						value={product.categoryId}
						onChange={inputHandler}
						className="form-select"
						id="categoryId"
						required
					>
						<option value="">Select a category</option>
						{categories.map(category => (
                            <option key={category._id} value={category._id}>{category.categoryName}</option>
                        ))}
					</select>
				</div>
				<div className="mb-3">
					<label htmlFor="name" className="form-label">Name</label>
					<input
						type="text"
						name="name"
						value={product.name}
						onChange={inputHandler}
						className="form-control"
						id="name"
						required
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="partNumber" className="form-label">Part Number</label>
					<input
						type="text"
						name="partNumber"
						value={product.partNumber}
						onChange={inputHandler}
						className="form-control"
						id="partNumber"
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="description" className="form-label">Description</label>
					<TinyMCEInput
						value={product.description}
						field="description"
						inputHandler={inputHandlerCk}
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="specifications" className="form-label">Specifications</label>
					<TinyMCEInput
						value={product.specifications}
						field="specifications"
						inputHandler={inputHandlerCk}
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="manualId" className="form-label">Manuals</label>
					<select
						name="manualId"
						value={product.manualId}
						onChange={inputHandler}
						className="form-select"
						id="manualId"
					>
						<option value="">Select a manual</option>
						{manuals.map(manual => (
							<option
								key={manual._id}
								value={manual._id}
							>
								{manual.title}
							</option>
						))}
					</select>
				</div>
				<div className="mb-3">
					<label htmlFor="images" className="form-label">Product Images</label>
					<input
						type="file"
						name="images"
						multiple
						onChange={handleFileChange}
						className="form-control"
						id="images"
						required={product.imagePath.length < 0}
					/>
					<br></br>
					{product.imagePath.length > 0 && (
						<div className="d-flex flex-wrap gap-2 mt-3">
							{product.imagePath.map((path, index) => (
								<div className="d-flex" key={index}>
									<FilePreview path={path} id={product._id} index={index} onRemove={handleRemoveImage}/>
								</div>
							))}
						</div>
					)}
				</div>
				<div className="mb-3">
					<div className="form-check form-switch">
						<input
							className="form-check-input"
							type="checkbox"
							role="switch"
							id="isFeatured"
							name="isFeatured"
							checked={product.isFeatured}
							onChange={inputHandler}
						/>
						<label className="form-check-label" htmlFor="isFeatured">
							{product.isFeatured ? 'Featured' : 'Not Featured'}
						</label>
					</div>
				</div>
				<button type="submit" className="btn btn-primary" disabled={isSubmitting}>{id ? 'Update' : 'Create'}</button>
				<br></br>
				<br></br>
			</form>
		</div>
	);
}

export default ProductEdit;
