import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import InquiryImg from 'assets/images/background-image/Inquery-img.webp';

const InquiryModal = ({ show, productName, handleClose, selectedAttribute, productID }) => {
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		phone: '',
		message: '',
		productID: productID || '',
		productName: productName || '',
		selectedAttribute: selectedAttribute || '',
	});

	useEffect(() => {
		setFormData(prev => ({
			...prev,
			productID: productID || '',
			productName: productName || '',
			selectedAttribute: selectedAttribute || ''
		}));
	}, [productName, selectedAttribute]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData(prev => ({
			...prev,
			[name]: value
		}));
	}

	const handleSave = async (e) => {
		e.preventDefault();
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}guest/product/inquiry/store`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData)
			});
			const data = await response.json();
			if (data.status) {
				toast.success('Inquiry added successfully', { position: 'top-right' });
				setFormData({
					name: '',
					email: '',
					phone: '',
					message: '',
					productID: productID || '',
					productName: productName || '',
					selectedAttribute: selectedAttribute || ''
				});
				handleClose();
			} else {
				toast.error(data.message._message, { position: 'top-right' });
			}
		} catch (error) {
			console.error('Error adding inquiry:', error);
			toast.error('Inquiry saving error', { position: 'top-right' });
		}
	}

	return (
		<div
			id="inquiry-modal"
			className={`modal fade ${show ? 'show d-block' : ''}`}
			tabIndex="-1"
			style={{ display: show ? 'block' : 'none' }}
			aria-labelledby="inquiryModalLabel"
			aria-hidden={!show}
		>
			<div className="modal-dialog modal-lg" role="dialog">
				<div className="modal-content">
					<div className="d-flex">
						<div className="me-3">
							<img src={InquiryImg} alt="Inquiry" width={390} />
						</div>
						<div className="flex-grow-1">
							<div className="modal-header">
								<h5 className="modal-title text-black text-center" id="inquiryModalLabel">Inquiry Now</h5>
								<button
									type="button"
									className="btn-close"
									onClick={handleClose}
									aria-label="Close"
								></button>
							</div>
							<form onSubmit={handleSave} className='inquiry-form'>
								<div className="mb-3">
									<label htmlFor="name" className="form-label text-black">Name:</label>
									<input
										type="text"
										id="name"
										name="name"
										className="form-control"
										required
										value={formData.name}
										onChange={handleChange}
									/>
								</div>

								<div className="mb-3">
									<label htmlFor="email" className="form-label text-black">Email:</label>
									<input
										type="email"
										id="email"
										name="email"
										className="form-control"
										required
										value={formData.email}
										onChange={handleChange}
									/>
								</div>

								<div className="mb-3">
									<label htmlFor="phone" className="form-label text-black">Phone:</label>
									<input
										type="tel"
										id="phone"
										name="phone"
										className="form-control"
										value={formData.phone}
										onChange={handleChange}
									/>
								</div>

								<div className="mb-3">
									<label htmlFor="message" className="form-label text-black">Message:</label>
									<textarea
										id="message"
										name="message"
										className="form-control"
										rows="4"
										required
										value={formData.message}
										onChange={handleChange}
									></textarea>
								</div>
								<div className='d-flex justify-content-center'>
									<button type="submit" className="btn btn-primary px-4">Submit</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default InquiryModal;
