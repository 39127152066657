import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Header from 'components/Header';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import SearchBar from 'components/HomeSections/SearchBar';
import Filter from 'views/products/Filter';
import List from 'views/products/List';
// import HeaderImage from 'assets/images/background-image/product-list.webp';
import IconGrid from 'assets/images/icons/grid-button.svg';
import IconList from 'assets/images/icons/list-button.svg';
import SubscribeButton  from 'components/SubscribeButton';

function Products() {
	const { categoryName } = useParams();
	const [itemsPerPage] = useState(9);
	const [view, setView] = useState('grid');
	const [products, setProducts] = useState([]);
	const [categories, setCategories] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [paginationData, setPaginationData] = useState();
	const [selectedCategories, setSelectedCategories] = useState([]);

	const handleToggle = (selectedView) => {
		setView(selectedView);
	};

	const handleFilterChange = useCallback((filter) => {
		setSelectedCategories(filter['selectedCategories']);
	}, []);

	const handleSearch = (value) => {
		setSearchTerm(value);
	};

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		if (categoryName) {
			const categoryFromUrl = categoryName.split(',');
			setSelectedCategories([...new Set(categoryFromUrl)]);
		} else {
			setSelectedCategories([]);
		}
	}, [categoryName]);

	const fetchProducts = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}guest/products?currentPage=${currentPage}&itemsPerPage=${itemsPerPage}&categories=${selectedCategories.join(',')}&search=${searchTerm}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				}
			});
			const data = await response.json();

			setPaginationData(data.paginationData);
			setProducts(data.data);
		} catch (error) {
			console.error('Not found data');
		}
	};

	const fetchCategories = async () => {
		try {
			const categoryResponse = await fetch(`${process.env.REACT_APP_API_URL}guest/category`);
			const data = await categoryResponse.json();
			setCategories(data.data);
		} catch (error) {
			console.error('Failed to fetch categories');
		}
	};

	useEffect(() => {
		fetchProducts();
	}, [currentPage, selectedCategories, searchTerm]);

	useEffect(() => {
		fetchCategories();
	}, []);

	function debounce(func, delay) {
		let timeoutId;
		return function (...args) {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
			timeoutId = setTimeout(() => {
				func(...args);
			}, delay);
		};
	}

	return (
		<>
			<Navbar />
			<Header page='product_page' />
			<div id="product-search" className="container-fluid bg-black">
				<div className="container bg-black py-3">
					<div className='py-2'>
						<nav aria-label="breadcrumb">
							<ol className="guest-breadcrumb breadcrumb">
								<li className="breadcrumb-item"><a href="/">Home</a></li>
								<li className="breadcrumb-item"><a href="#">Products</a></li>
							</ol>
						</nav>
					</div>
					<div className="d-flex flex-column flex-sm-row justify-content-between mb-3">
						<div className="me-2">
							<SearchBar onSearch={ debounce(handleSearch, 500) } />
						</div>
						<div>
							<div className="toggle-container">
								<div className="toggle-options">
									<div
										className={`toggle-option ${view === 'grid' ? 'active' : ''}`}
										onClick={() => handleToggle('grid')}
									>
										<img src={IconGrid} width={16} className='m-1' />
										Grid
									</div>
									<div
										className={`toggle-option ${view === 'list' ? 'active' : ''}`}
										onClick={() => handleToggle('list')}
									>
										<img src={IconList} width={16} className='m-1' />
										List
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row my-4">
						<div className="col-md-4 col-lg-4 mb-4 mb-md-0">
							<Filter
								products={products}
								categories={categories}
								onFilterChange={handleFilterChange}
							/>
						</div>
						<div className="col-md-8 col-lg-8">
							<List
								view={view}
								products={products}
								paginationData={paginationData}
								onPageChange={handlePageChange}
							/>
						</div>
					</div>
				</div>
			</div>
			<Footer />
			<SubscribeButton />
		</>
	);
}

export default Products;
