import React from 'react';
import { Link } from 'react-router-dom';
import { CRow, CCol, CCard, CCardHeader, CCardBody, CTable, CTableHead, CTableRow, CTableHeaderCell, CTableBody, CTableDataCell, CButton } from '@coreui/react';
import { cilTrash, cilPencil } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import PaginationButton from './PaginationButton';
import SearchIcon from 'assets/images/search.svg'

const DynamicTable = ({ config, tableData, handleDelete, paginationData, onPageChange , onSearchChange }) => {
	function debounce(func, delay) {
		let timeoutId;
		return function (...args) {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
			timeoutId = setTimeout(() => {
				func(...args);
			}, delay);
		};
	}
	return (
		<CRow>
			<CCol xs={12}>
				<CCard className="mb-4">
					<CCardHeader>
						<strong>{config.headerTitle}</strong>
						<small>{config.headerSubtitle}</small>
					</CCardHeader>
					<CCardBody>
						<CRow className="align-items-center my-3">
							<CCol>
								{config.search && config.search.placeholder ? (
									<div className="d-flex justify-content-center">
										<div className="input-group col-6">
											<span className="input-group-text">
												<img src={SearchIcon} alt="Search" />
											</span>
											<input 
												type="text" 
												className="form-control" 
												placeholder={config.search.placeholder}
												onChange={debounce(onSearchChange || (() => {}), 500)}
											/>
										</div>
									</div>
								) : (
									<p className="text-body-secondary small mb-0">{config.listDescription}</p>
								)}
							</CCol>
							<CCol className="d-flex justify-content-end">
								<Link className='text-white text-decoration-none' to={config.addButtonLink}>
									<CButton color="primary">
										{config.addButtonLabel}
									</CButton>
								</Link>
							</CCol>
						</CRow>
						<CTable>
							<CTableHead>
								<CTableRow>
									{config.tableHeaders.map((header, index) => (
										<CTableHeaderCell scope="col" key={index}>{header}</CTableHeaderCell>
									))}
								</CTableRow>
							</CTableHead>
							<CTableBody>
								{tableData.length === 0 ? (
									<CTableRow>
										<CTableDataCell colSpan={config.tableHeaders.length} className="text-center">
											No data available
										</CTableDataCell>
									</CTableRow>
								) : (
									tableData.map((row, index) => (
										<CTableRow key={index}>
											{config.tableFields.map((field, fieldIndex) => (
												<CTableDataCell key={fieldIndex}>
													{field === 'action' ? (
														<>
															<Link className='text-white' to={`${config.editButtonLink}/${row[field]}`}>
																<CButton color="primary" className="me-2">
																	<CIcon icon={cilPencil} />
																</CButton>
															</Link>
															<CButton color="danger" onClick={() => handleDelete(row[field])}>
																<CIcon className='text-white' icon={cilTrash} />
															</CButton>
														</>
													) : (
														row[field]
													)}
												</CTableDataCell>
											))}
										</CTableRow>
									))
								)}
							</CTableBody>
						</CTable>
					</CCardBody>
				</CCard>
			</CCol>
			<PaginationButton
				 paginationData={paginationData}
				 onPageChange={onPageChange}
			/>
		</CRow>
	);
};

export default DynamicTable;
