import React, { useEffect, useState } from "react";
import axios from "axios";
import DynamicTable from "components/Admin/DynamicTable";

const config = {
	headerTitle: "News",
	listDescription: "Here is the News List",
	addButtonLabel: "Add News",
	addButtonLink: "/admin/news/add",
	editButtonLink: "/admin/news/edit",
	tableHeaders: ["#", "Title", "Is Featured", "Action"],
	tableFields: ["id", "title", "isFeatured", "action"],
};

function News() {
	const [news, setNews] = useState([]);
	const [paginationData, setPaginationData] = useState({});

	const fetchData = async (page = 1) => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}admin/news?page=${page}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			});
			const data = await response.json();
			if (data.status) {
				setPaginationData(data.paginationData);
				setNews(data.data);
			} else {
				console.error("Unexpected response format or status is false:", data);
			}
		} catch (error) {
			console.error("Error fetching news:", error);
			setNews([]); // Reset news in case of error
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const onPageChange = async (page) => {
		await fetchData(page);
	};

	const handleDelete = async (id) => {
		if (!window.confirm("Are you sure you want to delete this news?")) {
			return;
		}

		try {
			await axios.delete(`${process.env.REACT_APP_API_URL}admin/news/delete/${id}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			});
			setNews((prevNews) => prevNews.filter((newsItem) => newsItem._id !== id));
		} catch (error) {
			console.error("Error deleting news:", error);
		}
	};

	const tableData = news.map((newsItem, index) => ({
		id: (paginationData.page - 1) * paginationData.limit + (index + 1),
		title: newsItem.title,
		isFeatured: newsItem.isFeatured ? 'True' : 'False',
		action: newsItem._id,
	}));

	return (
		<DynamicTable
			config={config}
			tableData={tableData}
			handleDelete={handleDelete}
			paginationData={paginationData}
			onPageChange={onPageChange}
		/>
	);
}

export default News;
