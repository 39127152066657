import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import axios from 'axios';

function ContactEdit() {
	const [contact, setContact] = useState({});
	const { id } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		const fetchContact = async () => {
			if (id) {
				try {
					const response = await fetch(`${process.env.REACT_APP_API_URL}admin/contact/detail/${id}`, { 
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						}
					 });
					const data = await response.json();
					setContact(data.data);
				} catch (error) {
					console.error('Error fetching contact:', error);
					toast.error('Failed to fetch contact details.');
				}
			}
		};
		fetchContact();
	}, [id]);

	const inputHandler = (e) => {
		const { name, value } = e.target;
		setContact((prev) => ({
			...prev,
			[name]: value
		}));
	};

	const saveContact = async (e) => {
		e.preventDefault();
		try {
			if (id) {
				await axios.put(`${process.env.REACT_APP_API_URL}admin/contact/update/${id}`, contact, { 
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				 });
				toast.success('Contact updated successfully!', {position : 'top-right'});
			} else {
				await axios.post(`${process.env.REACT_APP_API_URL}admin/contact/store`, contact, { 
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				 });
				toast.success('Contact created successfully!', {position : 'top-right'});
			}
			navigate('/admin/contact/index');
		} catch (error) {
			console.error('Error saving contact:', error);
			toast.error('Failed to save contact.' , {position : 'top-right'});
		}
	};

	return (
		<div className='container'>
			<h1>{id ? 'Edit Contact' : 'Add Contact'}</h1>
			<form onSubmit={saveContact}>
				<div className="mb-3">
					<label htmlFor="firstName" className="form-label">First Name</label>
					<input
						type="text"
						name="firstName"
						value={contact.firstName || ''}
						onChange={inputHandler}
						className="form-control"
						id="firstName"
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="lastName" className="form-label">Last Name</label>
					<input
						type="text"
						name="lastName"
						value={contact.lastName || ''}
						onChange={inputHandler}
						className="form-control"
						id="lastName"
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="email" className="form-label">Email</label>
					<input
						type="email"
						name="email"
						value={contact.email || ''}
						onChange={inputHandler}
						className="form-control"
						id="email"
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="phone" className="form-label">Phone</label>
					<input
						type="tel"
						name="phone"
						value={contact.phone || ''}
						onChange={inputHandler}
						className="form-control"
						id="phone"
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="message" className="form-label">message</label>
					<textarea
						type="tel"
						name="message"
						value={contact.message || ''}
						onChange={inputHandler}
						className="form-control"
						id="message"
						rows={5}
					/>
				</div>
				<button type="submit" className="btn btn-primary mb-3">
					{id ? 'Save Changes' : 'Add Contact'}
				</button>
			</form>
		</div>
	);
}

export default ContactEdit;
