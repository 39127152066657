import { CContainer, CNavbar , CNavLink , CNavbarBrand, CNavbarNav, CNavItem, CNavbarToggler, CCollapse } from '@coreui/react';
import NavbarLogo from 'assets/images/navbar-logo.png';
import { NavLink } from 'react-router-dom';
import React, { useState } from 'react';

const Navbar = () => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleNavbar = () => setIsOpen(!isOpen);

	return (
		<CNavbar expand="lg" color="black" className="bg-dark" id='navbar'>
			<CContainer>
				<CNavbarBrand href="/">
					<img 
						src={NavbarLogo} 
						alt="My App Logo" 
						style={{ height: '40px', width: 'auto' }} 
					/>
				</CNavbarBrand>
				<CNavbarToggler
					onClick={toggleNavbar}
					aria-controls="navbar-nav"
					aria-expanded={isOpen}
					aria-label="Toggle navigation"
				/>
				<CCollapse id="navbar-nav" className={`navbar-collapse ${isOpen ? 'show' : ''}`}>
					<CNavbarNav className="ms-auto">
						<CNavItem>
							<CNavLink to="/" as={NavLink} className='px-lg-3'>
								Home
							</CNavLink>
						</CNavItem>
						<CNavItem>
							<CNavLink to="/about" as={NavLink} className='px-lg-3'>
								About
							</CNavLink>
						</CNavItem>
						<CNavItem>
							<CNavLink to="/products" as={NavLink} className='px-lg-3'>
								Products
							</CNavLink>
						</CNavItem>
						<CNavItem>
							<CNavLink to="/projects" as={NavLink} className='px-lg-3'>
								Projects
							</CNavLink>
						</CNavItem>
						<CNavItem>
							<CNavLink to="/news" as={NavLink} className='px-lg-3'>
								News
							</CNavLink>
						</CNavItem>
						<CNavItem>
							<CNavLink to="/contact" as={NavLink} className='px-lg-3'>
								Contact
							</CNavLink>
						</CNavItem>
					</CNavbarNav>
				</CCollapse>
			</CContainer>
		</CNavbar>
	);
};

export default Navbar;
