import React from 'react';
import IconLeft from 'assets/images/icons/left-move-btn.svg';
import IconRight from 'assets/images/icons/right-move-btn.svg';

const Pagination = ({ totalItems, itemsPerPage, currentPage, onPageChange }) => {
	const totalPages = Math.ceil(totalItems / itemsPerPage);

	if (totalPages <= 1) return null;

	const handleClick = (page) => {
		if (page >= 1 && page <= totalPages) {
			onPageChange(page);
		}
	};

	const paginationStyles = {
		container: {
			display: 'flex',
			justifyContent: 'flex-end',
			margin: '20px 0'
		},
		list: {
			listStyle: 'none',
			padding: 0,
			margin: 0,
			display: 'flex',
			alignItems: 'center'
		},
		item: {
			margin: '0 5px'
		},
		button: {
			backgroundColor: '#3a40c3',
			border: '1px solid #3a40c3',
			color: 'white',
			padding: '8px 12px',
			borderRadius: '50%',
			cursor: 'pointer',
			fontSize: '16px',
			outline: 'none',
			transition: 'background-color 0.2s ease',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: '40px',
			height: '40px'
		},
		buttonHover: {
			backgroundColor: '#ff5722'
		},
		disabled: {
			backgroundColor: '#e9ecef',
			borderColor: '#e9ecef',
			color: '#6c757d',
			cursor: 'not-allowed'
		},
		active: {
			backgroundColor: '#ff5722',
			borderColor: '#ff5722'
		},
		firstPageActive: {
			backgroundColor: '#ff5722', // Different color for the first page
			borderColor: '#ff5722'
		}
	};

	return (
		<div style={paginationStyles.container}>
			<ul style={paginationStyles.list}>
				<li style={paginationStyles.item}>
					<button
						style={{
							...paginationStyles.button,
							...(currentPage === 1 && paginationStyles.disabled),
						}}
						onClick={() => handleClick(currentPage - 1)}
						disabled={currentPage === 1}
					>
						<img 
							src={IconLeft}
							alt="Previous"
							style={{ width: '24px', height: '24px' }}
						/>
					</button>
				</li>
				{Array.from({ length: totalPages }, (_, index) => (
					<li key={index + 1} style={paginationStyles.item}>
						<button
							style={{
								...paginationStyles.button,
								...(currentPage === index + 1
									? (index + 1 === 1
										? paginationStyles.firstPageActive
										: paginationStyles.active)
									: {})
							}}
							onClick={() => handleClick(index + 1)}
						>
							{index + 1}
						</button>
					</li>
				))}
				<li style={paginationStyles.item}>
					<button
						style={{
							...paginationStyles.button,
							...(currentPage === totalPages && paginationStyles.disabled),
						}}
						onClick={() => handleClick(currentPage + 1)}
						disabled={currentPage === totalPages}
					>
						<img
							src={IconRight}
							alt="Next"
							style={{ width: '24px', height: '24px' }}
						/>
					</button>
				</li>
			</ul>
		</div>
	);
};

export default Pagination;
