import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { CContainer } from '@coreui/react';
import CategoryCard from 'components/HomeSections/CategoryCard';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import DropLine from 'assets/images/icons/drop-line.svg';
import BtnLeft from 'assets/images/icons/left-move-btn.svg';
import BtnRight from 'assets/images/icons/right-move-btn.svg';

const CategoryList = () => {
	const [categories, setCategories] = useState([]);
	const sliderRef = useRef(null);
	const navigate = useNavigate();

	const fetchCategories = useCallback(async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}guest/category`);
			const data = await response.json();
			setCategories(data.data);
		} catch (error) {
			console.error("Error fetching categories:", error);
		}
	}, []);

	useEffect(() => {
		fetchCategories();
		// Cleanup function to avoid memory leaks if the component unmounts
		return () => {
			setCategories([]);
		};
	}, [fetchCategories]);

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 2,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};

	return (
		<div className="container-fluid bg-black text-white py-4">
			<CContainer>
				<div className="d-flex justify-content-between py-4">
					<div>
						<h1>Product Categories</h1>
						<img src={DropLine} className="drop-line pointer" alt="Drop Line" width={100} />
					</div>
					<div>
						<button
							className="bg-pink px-3 py-2 text-white border-0 rounded"
							onClick={() => navigate('/categories')}
						>
							View All <span className="arrow-icon">→</span>
						</button>
					</div>
				</div>
				<Slider ref={sliderRef} {...settings}>
					{Array.isArray(categories) && categories.map((category) => (
						<div className="mb-4" key={category._id}>
							<CategoryCard
								image={category.filePath}
								name={category.categoryName}
								render={`products/${category.categoryName}`}
							/>
						</div>
					))}
				</Slider>
				<div className="scroller-slider-controls">
					<img
						onClick={() => sliderRef.current.slickPrev()}
						src={BtnLeft}
						width={40}
						alt="Previous slide"
						className="img-fluid pointer"
					/>
					<img
						onClick={() => sliderRef.current.slickNext()}
						src={BtnRight}
						width={40}
						alt="Next slide"
						className="img-fluid pointer"
					/>
				</div>
			</CContainer>
			<br />
		</div>
	);
};

export default React.memo(CategoryList);
