import React from "react";
import { Navigate } from "react-router-dom";

const isAuthenticated = () => {
	return localStorage.getItem("token") !== null;
};

const ProtectedRoute = ({ element }) => {
	if (!isAuthenticated()) {
		return <Navigate to="/" />;
	}

	return element;
};

export default ProtectedRoute;