import React, { useEffect, useState } from "react";
import axios from "axios";
import DynamicTable from "components/Admin/DynamicTable";

const config = {
	headerTitle: "Manuals",
	listDescription: "Here is the Manuals List",
	addButtonLabel: "Add Manual",
	addButtonLink: "/admin/manual/add",
	editButtonLink: "/admin/manual/edit",
	tableHeaders: ["#", "Name", "Attached", "Action"],
	tableFields: ["id", "title", "attachment", "action"],
};

function ManualsList() {
	const [manuals, setManuals] = useState([]);
	const [paginationData, setPaginationData] = useState({});

	const fetchData = async (page = 1) => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}admin/manuals?page=${page}`,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				}
			);
			const data = await response.json();
			if (data.status) {
				setPaginationData(data.paginationData);
				setManuals(data.data);
			}
		} catch (error) {
			console.error("Error fetching manuals:", error);
			setManuals([]);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const onPageChange = async (page) => {
		await fetchData(page);
	};

	const handleDelete = async (id) => {
		if (!window.confirm("Are you sure, to delete this manual?")) {
			return;
		}

		try {
			await axios.delete(`${process.env.REACT_APP_API_URL}admin/manual/delete/${id}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			});
			setManuals((prevManuals) => prevManuals.filter((manual) => manual._id !== id));
		} catch (error) {
			console.error("Error deleting manuals:", error);
		}
	};

	const tableData = manuals.map((manual, index) => ({
		id: (paginationData.page - 1) * paginationData.limit + (index + 1),
		title: manual.title,
		attachment: manual.filePaths.length > 0 
			? `${manual.filePaths.length} File${manual.filePaths.length > 1 ? 's' : ''}` 
			: 'No Files',
		action: manual._id,
	}));

	return (
		<DynamicTable
			config={config}
			tableData={tableData}
			handleDelete={handleDelete}
			paginationData={paginationData}
			onPageChange={onPageChange}
		/>
	);
}

export default ManualsList;
