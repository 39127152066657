import React from 'react';
import { parseISO, formatDistanceToNow } from 'date-fns';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { CCard, CCardText } from '@coreui/react';
import CalenderIcon from "assets/images/icons/calender.svg";
import DOMPurify from 'dompurify';

const formatDate = (dateStr) => {
	if (!dateStr) return '';
	try {
		const dateParsed = parseISO(dateStr);
		return formatDistanceToNow(dateParsed, { addSuffix: true });
	} catch (error) {
		console.error('Date parsing error:', error);
		return '';
	}
};

const RenderHtml = ({ htmlContent }) => {
	const sanitizedHTML = DOMPurify.sanitize(htmlContent);
  
	return (
	  <span dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
	);
};

const ProductCard = ({ image, name, description, partNumber, date, render }) => {
	return (
		<Link className='text-decoration-none text-white pointer' to={`${render}`}>
			<CCard className='bg-dark border p-3 rounded-4'>
				<img
					src={image}
					alt={name}
					className="rounded-4 mt-2"
					style={{ height: '180px', objectFit: 'cover', width: '100%' }}
				/>
				<div className='text-white pt-2'>
						<h6 className="mb-2 pointer">{name}</h6>
						{partNumber && (
							<h6>#{partNumber}</h6>
						)}
						<p>
							<RenderHtml htmlContent={description} />
						</p>
					{date && (
						<CCardText className="pt-2">
							<img src={CalenderIcon} width={30} alt="Calendar Icon" />
							<span className='p-2'>{formatDate(date)}</span>
						</CCardText>
					)}
				</div>
			</CCard>
		</Link>
	);
};

ProductCard.propTypes = {
	image: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	description: PropTypes.string,
	date: PropTypes.string,
};

export default ProductCard;
