import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "views/Home";
import About from "views/About";
import ProductSearch from "views/products";
import ProductDetail from "views/products/ProductDetail";
import Projects from "views/Projects";
import ProjectDetail from "views/ProjectDetail";
import News from "views/News";
import NewsDetail from "views/NewsDetail";
import Contact from "views/Contact";
import StaticPage from "views/static-page/index";
import Categories from "views/Category";

import Login from "views/auth/Login";
// import Register from "views/auth/Register";

import AdminLayout from "./components/Admin/AdminLayout";
import ProtectedRoute from "./components/ProtectedRoute";

import NotFound from "views/NotFound";

import 'scss/style.scss';
import 'App.css';

// Fetch Meta Settings (Title, Meta Description, Meta Keywords)
const useFetchSettings = () => {
	useEffect(() => {
		const metaInfo = localStorage.getItem('metaInfo');
		if (metaInfo) {
			const data = JSON.parse(metaInfo);
			document.querySelector('meta[name="description"]').content = data.metaDescription;
			document.querySelector('meta[name="keywords"]').content = data.metaKeyword;
			document.title = data.metaTitle || 'Maxtron Show Lighting';
		} else {
			(async () => {
				try {
					const response = await fetch(`${process.env.REACT_APP_API_URL}guest/meta-setting-detail`);
					const data = await response.json();
					localStorage.setItem('metaInfo', JSON.stringify(data.data));
					document.querySelector('meta[name="description"]').content = data.data.metaDescription;
					document.querySelector('meta[name="keywords"]').content = data.data.metaKeyword;
					document.title = data.data.metaTitle || 'Maxtron Show Lighting';
				} catch (error) {
					console.error('Error fetching meta data:', error);
				}
			})();
		}
	}, []);
};

function App() {
	useEffect(() => {
		const settings = localStorage.getItem('settings');
		if (!settings) {
			(async () => {
				try {
					const response = await fetch(`${process.env.REACT_APP_API_URL}guest/setting-detail`);
					const data = await response.json();

					if (data.data) {
						const settingsObject = data.data.reduce((acc, setting) => {
							const type = setting.type.replace('-', '_');
							acc[type] = {
								heading: setting.heading || '',
								subHeading: setting.subHeading || '',
								headingImage: setting.headingImage || '',
								youtubeUrl: setting.youtubeUrl || '',
								aboutTwoHeading: setting.aboutTwoHeading || '',
								aboutTwoDetail: setting.aboutTwoDetail || '',
								aboutTwoFile: setting.aboutTwoFile || '',
								sectionTwoHeading: setting.sectionTwoHeading || '',
								sectionTwoSubHeading: setting.sectionTwoSubHeading || '',
								sectionTwoDescription: setting.sectionTwoDescription || '',
								sectionThreeHeading: setting.sectionThreeHeading || '',
								sectionThreeDescription: setting.sectionThreeDescription || '',
								featuredProjectTopRightText: setting.featuredProjectTopRightText || '',
								featuredProjectBottomRightText: setting.featuredProjectBottomRightText || '',
								featuredProjectCenterText: setting.featuredProjectCenterText || '',
								featuredProjectTopLeftText: setting.featuredProjectTopLeftText || '',
								featuredProjectBottomLeftText: setting.featuredProjectBottomLeftText || '',
								featuredProjectTopRightFile: setting.featuredProjectTopRightFile || '',
								featuredProjectBottomRightFile: setting.featuredProjectBottomRightFile || '',
								featuredProjectCenterFile: setting.featuredProjectCenterFile || '',
								featuredProjectTopLeftFile: setting.featuredProjectTopLeftFile || '',
								featuredProjectBottomLeftFile: setting.featuredProjectBottomLeftFile || '',
								sideFixedButton: setting.sideFixedButton || '',
								sideFixedButtonUrl: setting.sideFixedButtonUrl || '',
							};
							return acc;
						}, {});
					
						localStorage.setItem('settings', JSON.stringify(settingsObject));
					}
				} catch (error) {
					console.error('Error fetching settings:', error);
				}
			})();
		}
	}, []);

	useFetchSettings();

	return (
		<Router>
			<Suspense fallback={<div>Loading...</div>}>
				<Routes>
					<Route exact path="/" element={<Home />} />
					<Route exact path="/about" element={<About />} />
					<Route exact path="/categories" element={<Categories />} />
					<Route exact path="/products" element={<ProductSearch />} />
					<Route exact path="/products/:categoryName" element={<ProductSearch />} />
					<Route exact path="/product/detail/:id" element={<ProductDetail />} />
					<Route exact path="/projects" element={<Projects />} />
					<Route exact path="/project/detail/:id" element={<ProjectDetail />} />
					<Route exact path="/news" element={<News />} />
					<Route exact path="/news/detail/:id" element={<NewsDetail />} />
					<Route exact path="/contact" element={<Contact />} />
					<Route exact path="/static/:slug" element={<StaticPage />} />

					<Route exact path="admin/login" element={<Login />} />

					<Route path="/admin/*" element={<ProtectedRoute element={<AdminLayout />} />} />

					<Route path="*" element={<NotFound />} />
				</Routes>
			</Suspense>
		</Router>
	);
}

export default App;
