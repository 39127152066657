import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import IconMessage from 'assets/images/icons/message.svg';
import IconBuilding from 'assets/images/icons/blue-building.svg';
import IconLocation from 'assets/images/icons/map-location.svg';
import ContactUsBg from 'assets/images/background-image/contact-us.webp';
import Twitter from 'assets/images/icons/black-x.svg';
import Linkedin from 'assets/images/icons/black-linkedin.svg';
import Facebook from 'assets/images/icons/black-facebook.svg';
import Instagram from 'assets/images/icons/pink-instagram.svg';

function Contact() {
	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		message: '',
	});

	useEffect(()=> {
		window.scrollTo(0, 0);
	})

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			await fetch(`${process.env.REACT_APP_API_URL}guest/contact/store`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData)
			});

			toast.success("Form submitted successfully", {position: 'top-right'});
			setFormData({
				firstName: '',
				lastName: '',
				email: '',
				phone: '',
				message: '',
			});
		} catch (error) {
			console.error("Error submitting the contact form:", error);
			toast.error("Failed to submit the form. Please try again.");
		}
	};

	return (
		<>
			<Navbar/>
			<div id="contact-page" className='container-fluid bg-black text-white pb-3'>
				<div className='container pb-5 text-center pt-4'>
					<h1>Contact Us</h1>
					<p>Any question or remarks? Just write us a message!</p>
				</div>
				<div className='container p-0 mb-5 bg-green rounded-4 d-flex flex-column flex-md-row'>
					<div className='col-md-5 bg-white p-4 rounded-4 text-dark contact-info'>
						<div className='p-3'>
							<h5>Contact Information</h5>
							<p>We'd love to hear from you! Send us a message.</p>
						</div>
						<div className='d-flex flex-column'>
							<div>
								<p>
									<img src={IconBuilding} width={35} className='p-2' alt='Building' />
									+971 50 116 4472
								</p>
							</div>
							<div>
								<p>
									<img src={IconMessage} width={35} className='p-2' alt='Message' />
									msl@maxtron.com
								</p>
							</div>
							<div>
								<p>
									<img src={IconLocation} width={35} className='p-2' alt='Location' />
									Maxtron Show Lighting FZ LLC FZ12-04 Al Hamra 
									<br></br>
									<span>
										Industrial Zone Ras Al Khaimah United Arab Emirates
									</span>
								</p>
							</div>
						</div>
						<div className='d-flex justify-content-between'>
							<div className='d-flex contact-social'>
								<a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
									<img src={Twitter} width={40} className='p-2' alt='Twitter' />
								</a>
								<a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
									<img src={Instagram} width={40} className='p-2' alt='Instagram' />
								</a>
								<a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
									<img src={Linkedin} width={40} className='p-2' alt='LinkedIn' />
								</a>
								<a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
									<img src={Facebook} width={40} className='p-2' alt='Facebook' />
								</a>
							</div>
							<div className='contact-img'>
								<img src={ContactUsBg} className='img-fluid' alt='Contact Us Background' />
							</div>
						</div>
					</div>
					<div className="col-md-7 p-4 d-flex flex-column align-items-center">
						<form onSubmit={handleSubmit} className="contact-form w-75">
							<div className="row w-100 mb-3">
								<div className="col-md-6">
									<label htmlFor="firstName" className="form-label text-white">First Name:</label>
									<input
										type="text"
										id="firstName"
										name="firstName"
										className="form-control border-bottom-only"
										value={formData.firstName}
										onChange={handleChange}
										required
									/>
								</div>
								<div className="col-md-6">
									<label htmlFor="lastName" className="form-label text-white">Last Name:</label>
									<input
										type="text"
										id="lastName"
										name="lastName"
										className="form-control border-bottom-only"
										value={formData.lastName}
										onChange={handleChange}
										required
									/>
								</div>
							</div>
							<div className="row w-100 mb-3">
								<div className="col-md-6">
									<label htmlFor="email" className="form-label text-white">Email:</label>
									<input
										type="email"
										id="email"
										name="email"
										className="form-control border-bottom-only"
										value={formData.email}
										onChange={handleChange}
										required
									/>
								</div>
								<div className="col-md-6">
									<label htmlFor="phone" className="form-label text-white">Phone Number:</label>
									<input
										type="tel"
										id="phone"
										name="phone"
										className="form-control border-bottom-only"
										value={formData.phone}
										onChange={handleChange}
										required
									/>
								</div>
							</div>
							<div className="row w-100">
								<div className="col-md-12">
									<label htmlFor="message" className="form-label text-white">Message:</label>
									<textarea
										id="message"
										name="message"
										className="form-control border-bottom-only"
										rows="4"
										value={formData.message}
										onChange={handleChange}
										required
									/>
								</div>
							</div>
							<div className="mt-3">
								<button type="submit" className="bg-pink text-white p-2 rounded-2">Send Message</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<Footer />
		</>

	);
}

export default Contact;
