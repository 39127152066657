import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CContainer, CRow, CCol, CCardImage } from '@coreui/react';
import Slider from 'react-slick';
import Navbar from 'components/Navbar';
import Header from 'components/Header';
import Footer from 'components/Footer';
import ProductCard from 'components/ProductCard';
import InquiryModal from 'components/InquiryModal';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import BtnRight from 'assets/images/icons/right-move-btn.svg';
import DownloadIcon from 'assets/images/icons/download.svg';
import BtnLeft from 'assets/images/icons/left-move-btn.svg';
import DOMPurify from 'dompurify';
import SubscribeButton  from 'components/SubscribeButton';

const RenderHtml = ({ htmlContent }) => {
	const sanitizedHTML = DOMPurify.sanitize(htmlContent);
  
	return (
	  <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
	);
};

const truncateDescription = (description, wordLimit = 15) => {
	if (!description) return null;
	const words = description.split(' ');
	return words.length <= wordLimit ? description : `${words.slice(0, wordLimit).join(' ')}...`;
};

const fetchData = async (url) => {
	try {
		const response = await fetch(url);
		const data = await response.json();
		return data;
	} catch (error) {
		console.error(`Error fetching data from ${url}:`, error);
	}
};

const ProductDetail = () => {
	const { id } = useParams();
	const [detail, setDetail] = useState(null);
	const [categoryAttr, setCategoryAttr] = useState([]);
	const [selectedAttrValues, setSelectedAttrValues] = useState({});
	const [manuals, setManuals] = useState([]);
	const [suggestion, setSuggestion] = useState([]);
	const [activeTab, setActiveTab] = useState('description');
	const [showModal, setShowModal] = useState(false);
	const sliderRef = useRef(null);
	const mainSliderRef = useRef(null);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchProductDetails = async () => {
			const productData = await fetchData(`${process.env.REACT_APP_API_URL}guest/product/detail/${id}`);
			if (!productData?.status) return navigate('/404');

			setDetail(productData.data);

			if (productData.data.categoryId) {
				const [suggestions, attrInfo, manualsData] = await Promise.all([
					fetchData(`${process.env.REACT_APP_API_URL}guest/product/suggestions/${productData.data.categoryId}`),
					fetchData(`${process.env.REACT_APP_API_URL}guest/category/attr-info/${productData.data.categoryId}`),
					productData.data.manualId ? fetchData(`${process.env.REACT_APP_API_URL}guest/manual/detail/${productData.data.manualId}`) : null
				]);
				setSuggestion(suggestions?.data || []);
        		setCategoryAttr(attrInfo?.data || []);
        		setManuals(manualsData?.data || []);
			}
		};

		fetchProductDetails();
	}, [id, navigate]);
	const sliderSettings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 2,
		responsive: [
			{ breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 1 } },
			{ breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 1 } },
			{ breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } }
		]
	};

	const mainSliderSettings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false
	};

	const handleShow = () => setShowModal(true);
	const handleClose = () => setShowModal(false);
	const handleSelectAttribute = (e, fieldName) => {
		const { value } = e.target;
		setSelectedAttrValues(prevState => ({
			...prevState,
			[fieldName]: value
		}));
	};

	if (!detail) return <div className="align-items-center vh-100 text-center d-grid">Loading...</div>;

	const { imagePath } = detail;

	return (
		<div id="product-detail">
			<Navbar />
			<Header page='product_page' />
			<CContainer className="my-5">
				<nav aria-label="breadcrumb" className='py-2'>
					<ol className="guest-breadcrumb breadcrumb">
						<li className="breadcrumb-item"><a href="/">Home</a></li>
						<li className="breadcrumb-item"><a href="/products">Products</a></li>
						<li className="breadcrumb-item"><a href="#">{detail.name}</a></li>
					</ol>
				</nav>

				<CRow>
					<CCol md={6} className="mb-4">
						<div className="slider-container position-relative">
							<Slider ref={mainSliderRef} {...mainSliderSettings} className="main-slider">
								{imagePath.map((path, index) => (
									<div key={index}>
										<CCardImage
											orientation="top"
											src={`${process.env.REACT_APP_BACKEND_URL}${path}` || "https://via.placeholder.com/600x400"}
											className="rounded-4"
											width={300}
											height={500}
										/>
									</div>
								))}
							</Slider>
							<div className="slider-controls position-absolute d-flex justify-content-between w-100">
								<img onClick={() => mainSliderRef.current.slickPrev()} src={BtnLeft} className="control-btn img-fluid pointer" alt="Previous slide" />
								<img onClick={() => mainSliderRef.current.slickNext()} src={BtnRight} className="control-btn img-fluid pointer" alt="Next slide" />
							</div>
						</div>
						<Slider {...sliderSettings} className="sub-slider mt-4">
							{imagePath.map((path, index) => (
								<div key={index} className="slider-item" onClick={() => mainSliderRef.current.slickGoTo(index)}>
									<CCardImage
										orientation="top"
										src={`${process.env.REACT_APP_BACKEND_URL}${path}` || "https://via.placeholder.com/150"}
										className="rounded-4"
										alt={`Thumbnail ${index + 1}`}
									/>
								</div>
							))}
						</Slider>
					</CCol>

					<CCol md={6}>
						<h1 className="mb-3">{detail.name}</h1>
						<h1 className="pink mb-4">
							<strong>#</strong> {detail.partNumber}
						</h1>

						{categoryAttr.length > 0 && categoryAttr.map(attr => (
							<div key={attr._id} className="py-2">
								<label htmlFor={attr._id} className="d-block py-1">{attr.fieldName}:</label>
								<select 
									id={attr._id} 
									className="form-select bg-black text-white"
									onChange={(e) => handleSelectAttribute(e, attr.fieldName)}
								>
									<option value="">Select {attr.fieldName}</option>
									{attr.options.map((option, index) => (
										<option key={index} value={option}>{option}</option>
									))}
								</select>
							</div>
						))}

						<div className="row py-2">
							<CCol md={6} className="mb-3">
								{manuals && manuals.filePaths && Array.isArray(manuals.filePaths) && manuals.filePaths.length > 0 ? (
									<>
										<label className="d-block py-1">Downloadable Manuals and PDFs:</label>
										{manuals.filePaths.map((path, index) => (
											<div key={index} className="mb-3">
												<a
													href={`${process.env.REACT_APP_BACKEND_URL}${path}`}
													className="btn btn-primary bg-pink text-white rounded-3"
													download
													target="_blank"
													rel="noopener noreferrer"
												>
													Download <img src={DownloadIcon} className="ms-2" alt="Download" />
												</a>
											</div>
										))}
									</>
								) : (
									<p>No downloadable manuals or PDFs available.</p>
								)}
							</CCol>
							<InquiryModal show={showModal} productName={detail.name} productID={detail._id} handleClose={handleClose} selectedAttribute={selectedAttrValues} />
							<CCol md={6}>
								<label className="d-block py-1">Product Inquiry:</label>
								<button
									className="btn btn-primary bg-pink text-white rounded-3"
									onClick={handleShow}
								>
									Inquiry Now
								</button>
							</CCol>
						</div>
					</CCol>
				</CRow>

				<div className="bg-green rounded-4 p-4">
					<div className="my-4">
						<ul className="nav nav-tabs">
							<li className="nav-item">
								<button
									className={`nav-link-btn px-4 ${activeTab === 'description' ? 'active-tab' : ''}`}
									onClick={() => setActiveTab('description')}
								>
									Description
								</button>
							</li>
							<li className="nav-item">
								<button
									className={`nav-link-btn px-4 ${activeTab === 'specification' ? 'active-tab' : ''}`}
									onClick={() => setActiveTab('specification')}
								>
									Specification
								</button>
							</li>
						</ul>
					</div>
					<div className="row">
						<div className="tab-content p-4">
							{activeTab === 'description' && (
								<div className="bg-green rounded-4">
									<RenderHtml htmlContent={detail.description} />
								</div>
							)}
							{activeTab === 'specification' && (
								<div className="bg-green rounded-4">
									<RenderHtml htmlContent={detail.specifications} />
								</div>
							)}
						</div>
					</div>
				</div>

				<div className="container py-4" id="products">
					<h2>Suggested Products</h2>
					<Slider {...sliderSettings} className={`${suggestion.length === 1 ? 'suggested-products-slider mt-4' : 'mt-4'}`}>
						{suggestion.map((product) => (
							<div className="px-2" key={product._id}>
								<ProductCard
									image={product.imagePath && product.imagePath.length > 0
										? `${process.env.REACT_APP_BACKEND_URL}${product.imagePath[0]}`
										: "https://via.placeholder.com/150"}
									name={product.name}
									partNumber={product.partNumber}
									render={`/product/detail/${product._id}`}
									description={truncateDescription(product.description)}
								/>
							</div>
						))}
					</Slider>
					<div className="d-flex justify-content-end gap-4 pt-2">
						<img onClick={() => sliderRef.current.slickPrev()} src={BtnLeft} width={40} alt="Previous slide" className="img-fluid pointer" />
						<img onClick={() => sliderRef.current.slickNext()} src={BtnRight} width={40} alt="Next slide" className="img-fluid pointer" />
					</div>
				</div>
			</CContainer>
			<Footer />
			<SubscribeButton />
		</div>
	);
};

export default ProductDetail;
