import React, { memo, Suspense, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CContainer, CRow, CCol } from '@coreui/react';
import DropLine from 'assets/images/icons/drop-line.svg';
import AboutImage from 'assets/images/sect-about-img-two.webp';
import './sections.css';

const About = () => {
	const [youtubeUrl, setYoutubeUrl] = useState('');
		const [aboutTwoHeading, setAboutTwoHeading] = useState('');
		const [aboutTwoDetail, setAboutTwoDetail] = useState('');
		const [aboutTwoFile, setAboutTwoFile] = useState(AboutImage);
		const navigate = useNavigate();

		const updateAboutData = (settings) => {
				if (settings && settings['home_page']) {
						const { youtubeUrl, aboutTwoHeading, aboutTwoDetail, aboutTwoFile } = settings['home_page'];

						const videoIdMatch = youtubeUrl.match(/(?:youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^&\n]{11})/);
						if (videoIdMatch) {
								setYoutubeUrl(`https://www.youtube.com/embed/${videoIdMatch[1]}`);
						}

						setAboutTwoHeading(aboutTwoHeading || '');
						setAboutTwoDetail(aboutTwoDetail || '');
						setAboutTwoFile(aboutTwoFile ? `${process.env.REACT_APP_BACKEND_URL}${aboutTwoFile}` : AboutImage);
				}
		};

		useEffect(() => {
				const storedSettings = localStorage.getItem('settings');

				if (storedSettings) {
					const settings = JSON.parse(storedSettings);
					updateAboutData(settings);
				} else {
					const checkSettings = setInterval(() => {
							const updatedSettings = localStorage.getItem('settings');
							if (updatedSettings) {
									clearInterval(checkSettings); // Stop the interval
									updateAboutData(JSON.parse(updatedSettings));
							}
					}, 1000);

					return () => clearInterval(checkSettings);
				}
		}, []);

	const getLastWord = (heading) => {
		const words = heading.trim().split(' ');
		return words[words.length - 1]; // Return the last word
	};

	return (
		<div className="about-container" id='about'>
			<CContainer className="py-4">
				<CRow className="align-items-center d-flex justify-content-around">
					<CCol md={6} className="text-box text-start">
						<div className='inner-text-box'>
							<h1>About</h1>
							<h5 className="p-0">Maxtron LED Lights</h5>
							<img src={DropLine} className='drop-line pointer' alt="Drop Line" width={100} />
							<p>
								The leading provider of LED lighting systems for amusement facilities, structures, and buildings.
							</p>
							<button className="p-2 px-3 text-white bg-pink" onClick={() => navigate('/about')}>
								Learn More
							</button>
						</div>
					</CCol>
					<CCol md={6} className="image-box">
						<Suspense fallback={<div>Loading video...</div>}>
							{youtubeUrl ? (
								<iframe
									className="w-100"
									width="560"
									height="315"
									src={youtubeUrl}
									title="YouTube video player"
									frameBorder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowFullScreen
								></iframe>
							) : (
								<div>No video available</div>
							)}
						</Suspense>
					</CCol>
				</CRow>
			</CContainer>
			<br></br>
			<br></br>
			<CContainer className="py-4 introduction-container">
				<CRow className="align-items-center d-flex justify-content-around">
					<CCol md={5} className="image-box">
					<div className="image-wrapper">
						<img src={aboutTwoFile} alt="Introduction" className="rounded-image" />
					</div>
					</CCol>
					<CCol md={6} className="text-box-two text-start p-4">
						<h1>
						{aboutTwoHeading.replace(/(\s\w+)$/, '')} <span className='pink'>{getLastWord(aboutTwoHeading)}</span>
						</h1>
						<div className="d-flex justify-content-center pb-2">
							<img src={DropLine} alt="Drop Line" width={100} />
						</div>
						<p>{aboutTwoDetail}</p>
					</CCol>
				</CRow>
			</CContainer>
		</div>
	);
};

export default memo(About);
