import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
	CButton,
	CCard,
	CCardBody,
	CCardGroup,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CInputGroup,
	CInputGroupText,
	CRow,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilLockLocked, cilUser } from '@coreui/icons';
import toast from 'react-hot-toast';

const Login = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const navigate = useNavigate();

	const handleLogin = async (e) => {
		e.preventDefault();
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}admin/login`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					email,
					password,
				}),
			});
			const data = await response.json();

			if (data && data.status && data.token) {
				localStorage.setItem('token', data.token);
				toast.success('Login successful!', { position: "top-right" });
				navigate('/admin/dashboard');
			} else {
				toast.error('Invalid response from server.', { position: "top-right" });
			}
		} catch (error) {
			if (error.response && error.response.status === 401) {
				toast.error('Invalid credentials. Please try again.', { position: "top-right" });
			} else if (error.response && error.response.status === 500) {
				toast.error('Server error. Please try again later.', { position: "top-right" });
			} else {
				toast.error('An error occurred. Please try again later.', { position: "top-right" });
			}
			console.error('Login error:', error);
		}
	};

	return (
		<div className="d-flex align-items-center justify-content-center min-vh-100 bg-light">
			<CContainer>
				<CRow className="justify-content-center">
					<CCol md={10} lg={8} xl={7}>
						<CCardGroup>
							<CCard className="p-4">
								<CCardBody>
									<CForm onSubmit={handleLogin}>
										<h1 className="text-center">Login</h1>
										<p className="text-muted text-center">Sign In to your account</p>
										<CInputGroup className="mb-3">
											<CInputGroupText><CIcon icon={cilUser} /></CInputGroupText>
											<CFormInput
												type="email"
												name="email"
												placeholder="Email"
												autoComplete="email"
												value={email}
												onChange={(e) => setEmail(e.target.value)}
												required
											/>
										</CInputGroup>
										<CInputGroup className="mb-4">
											<CInputGroupText><CIcon icon={cilLockLocked} /></CInputGroupText>
											<CFormInput
												name="password"
												type="password"
												placeholder="Password"
												autoComplete="current-password"
												value={password}
												onChange={(e) => setPassword(e.target.value)}
												required
											/>
										</CInputGroup>
										<CRow>
											<CCol xs={6}>
												<CButton type="submit" color="primary" className="px-4">Login</CButton>
											</CCol>
											<CCol xs={6} className="text-end">
												{/* <Link to="/forgot-password">
													<CButton color="link" className="px-0">Forgot password?</CButton>
												</Link> */}
											</CCol>
										</CRow>
									</CForm>
								</CCardBody>
							</CCard>
							<CCard className="text-white bg-primary py-5 d-md-down-none" style={{ width: '44%' }}>
								<CCardBody className="text-center d-flex align-items-center justify-content-center flex-column">
									<div>
										<h2>Sign up</h2>
										<p>
											This website gives you a technical overview about LED light modules to help you with your design.
										</p>
										<Link to="/register">
											<CButton color="primary" className="mt-3">Register Now!</CButton>
										</Link>
									</div>
								</CCardBody>
							</CCard>
						</CCardGroup>
					</CCol>
				</CRow>
			</CContainer>
		</div>
	);
};

export default Login;