import axios from 'axios';
import toast from 'react-hot-toast';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FilePreview from 'components/Admin/FilePreview';

function ManualEdit() {
	const { id } = useParams();
	const navigate = useNavigate();
	const [manual, setManual] = useState({ title: '', description: '', filePaths: [] });
	const [files, setFiles] = useState([]);
	const [isSubmitting, setIsSubmitting] = useState(false);

	useEffect(() => {
		if (id) {
			const manualDetail = async () => {
				try {
					const response = await fetch(`${process.env.REACT_APP_API_URL}admin/manual/detail/${id}`, { 
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						}
					 });
					const data = await response.json();
					if (data.status) {
						setManual(data.data);
					}
				} catch (error) {
					console.error('Not Found');
				}
			}
			manualDetail();
		}
	}, [id]);

	const inputHandler = (e) => {
		const { name, value } = e.target;
		setManual((prev) => ({
			...prev,
			[name]: value
		}));
	};

	const handleFiles = (e) => {
		setFiles(Array.from(e.target.files));
	};

	const saveManual = async (e) => {
		e.preventDefault();
		setIsSubmitting(true);
	
		const formData = new FormData();
		formData.append('title', manual.title);
		formData.append('description', manual.description);
	
		files.forEach((file) => {
			formData.append('manualFiles', file);
		});
	
		try {
			const url = id
				? `${process.env.REACT_APP_API_URL}admin/manual/update/${id}`
				: `${process.env.REACT_APP_API_URL}admin/manual/create`;
			const method = id ? 'PUT' : 'POST';
			await axios({
				url,
				method,
				data: formData,
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
					'Content-Type': 'multipart/form-data',
				}
			});
			navigate('/admin/manuals/index');
			toast.success('Manual saved successfully!', { position: 'top-right' });
		} catch (error) {
			console.error('Error saving manual:', error);
			toast.error('Failed to save manual.', { position: 'top-right' });
		}
	};

	return (
		<div className='container'>
			<h1>{id ? 'Edit Manual' : 'Add Manual'}</h1>
			<form onSubmit={saveManual}>
				<div className="mb-3">
					<label htmlFor="title" className="form-label">Title</label>
					<input
						type="text"
						name="title"
						value={manual.title || ''}
						onChange={inputHandler}
						className="form-control"
						id="title"
						required
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="description" className="form-label">Description</label>
					<textarea
						name="description"
						value={manual.description || ''}
						onChange={inputHandler}
						className="form-control"
						id="description"
						rows={6}
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="manualFiles" className="form-label">Files</label>
					<input
						type="file"
						name="manualFiles"
						onChange={handleFiles}
						className="form-control"
						id="manualFiles"
						multiple
					/>
					{manual.filePaths && manual.filePaths.length > 0 && (
						<div className="d-flex flex-wrap gap-2 mt-1">
							{manual.filePaths.map((path, index) => (
								<div className="d-flex" key={index}>
									<FilePreview path={path} />
								</div>
							))}
						</div>
					)}
				</div>
				<button type="submit" className="btn btn-primary mb-3" disabled={isSubmitting}>{id ? 'Update' : 'Create'}</button>
			</form>
		</div>
	);
}

export default ManualEdit;
