import axios from 'axios';
import toast from 'react-hot-toast';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function StaticPage() {
	const [staticData, setStaticData] = useState({
		url: '',
		heading: '',
		content: '',
		backgroundImage: null,
	});
	const [urlError, setUrlError] = useState('');
	const navigate = useNavigate();
	const { id } = useParams();

	useEffect(() => {
		if (id) {
			const fetchData = async () => {
				try {
					const response = await fetch(`${process.env.REACT_APP_API_URL}admin/static-page/detail/${id}`, { 
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						}
					});
					const data = await response.json();
					setStaticData({
						slug: data.data.slug,
						heading: data.data.heading,
						content: data.data.content,
						backgroundImage: data.data.filePath,
					});
				} catch (error) {
					console.error("Error fetching static page data:", error);
					toast.error(error.response?.data?.message || 'An error occurred', { position: 'top-right' });
				}
			};
			fetchData();
		}
	}, [id]);

	const inputHandler = (e) => {
		const { name, value } = e.target;
		let processedValue = value;

		if (name === 'slug') {
			processedValue = value.replace(/\s+/g, '-');

			const regex = /^[a-zA-Z0-9-]*$/; // Adjust regex to allow hyphens
			if (!regex.test(processedValue)) {
				setUrlError('Only letters, numbers, and hyphens are allowed.');
			} else {
				setUrlError('');
			}
		}

		setStaticData(prev => ({
			...prev,
			[name]: processedValue,
		}));
	};

	const fileInputHandler = (e) => {
		const file = e.target.files[0];
		setStaticData(prev => ({
			...prev,
			backgroundImage: file,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (urlError) {
			toast.error('Please fix the errors before submitting.', { position: 'top-right' });
			return;
		}

		const formData = new FormData();
		formData.append('slug', staticData.slug);
		formData.append('heading', staticData.heading);
		formData.append('content', staticData.content);
		if (staticData.backgroundImage) {
			formData.append('backgroundImage', staticData.backgroundImage);
		}

		try {
			if (id) {
				await axios.put(`${process.env.REACT_APP_API_URL}admin/static-page/update/${id}`, formData, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
						'Content-Type': 'multipart/form-data',
					}
				});
				toast.success('Static page updated successfully.', { position: 'top-right' });
			} else {
				await axios.post(`${process.env.REACT_APP_API_URL}admin/static-page/create`, formData, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
						'Content-Type': 'multipart/form-data',
					}
				});
				toast.success('Static page added successfully.', { position: 'top-right' });
			}
			navigate('/admin/static-pages/index');
		} catch (error) {
			console.error("Error saving static page:", error);
			toast.error(error.response?.data?.message || 'An error occurred', { position: 'top-right' });
		}
	};

	return (
		<div className='container'>
			<h1>{id ? 'Edit Static Page' : 'Add Static Page'}</h1>
			<form onSubmit={handleSubmit}>
				<div className="mb-3">
					<label htmlFor="slug" className="form-label">Slug</label>
					<input
						type="text"
						name="slug"
						value={staticData.slug}
						onChange={inputHandler}
						className="form-control"
						id="slug"
						required
					/>
					{urlError && <div className="text-danger">{urlError}</div>}
				</div>
				<div className="mb-3">
					<label htmlFor="heading" className="form-label">Heading</label>
					<input
						type="text"
						name="heading"
						value={staticData.heading}
						onChange={inputHandler}
						className="form-control"
						id="heading"
						required
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="content" className="form-label">Content</label>
					<textarea
						name="content"
						value={staticData.content}
						onChange={inputHandler}
						className="form-control"
						id="content"
						required
						rows={6}
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="backgroundImage" className="form-label">Background Image Header</label>
					<input
						type="file"
						name="backgroundImage"
						onChange={fileInputHandler}
						className="form-control"
						id="backgroundImage"
					/>
					{id && staticData.backgroundImage && (
						<div className="d-flex flex-wrap gap-2 mt-1">
							<div className="d-flex">
								<img
									src={`${process.env.REACT_APP_BACKEND_URL}${staticData.backgroundImage}`}
									alt="Background Preview"
									style={{ maxWidth: '200px', maxHeight: '200px' }}
								/>
							</div>
						</div>
					)}
				</div>
				<button type="submit" className="btn btn-primary mb-3" disabled={!!urlError}>
					{id ? 'Save Changes' : 'Add Static Page'}
				</button>
			</form>
		</div>
	);
}

export default StaticPage;
