import React from 'react'
import AppHeader from "components/Admin/AppHeader";
import AppSidebar from "components/Admin/AppSidebar";
import AppContent from "components/Admin/AppContent";
import AppFooter from "components/Admin/AppFooter";

const AdminLayout = () => {
	return (
		<div>
			<AppSidebar />
			<div className="wrapper d-flex flex-column min-vh-100">
				<AppHeader />
				<div className="body flex-grow-1">
					<AppContent />
				</div>
				<AppFooter />
			</div>
		</div>
	)
}

export default AdminLayout
