import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
	CCloseButton,
	CSidebar,
	CSidebarBrand,
	CSidebarFooter,
	CSidebarHeader,
	CSidebarToggler,
} from '@coreui/react'

// Import the logo image
import logo from 'assets/images/navbar-logo.png'

// sidebar nav config
import navigation from './SidebarMenu'
import { AppSidebarNav } from './AppSidebarNav'

const AppSidebar = () => {
	const dispatch = useDispatch()
	const unfoldable = useSelector((state) => state.sidebarUnfoldable)
	const sidebarShow = useSelector((state) => state.sidebarShow)

	return (
		<CSidebar
			className="border-end"
			colorScheme="dark"
			position="fixed"
			unfoldable={unfoldable}
			visible={sidebarShow}
			onVisibleChange={(visible) => {
				dispatch({ type: 'set', sidebarShow: visible })
			}}
		>
			<CSidebarHeader className="border-bottom d-flex justify-content-center">
				<CSidebarBrand to="/" className="logo-container">
					<img src={logo} alt="Logo" className="logo-img" width={100} />
				</CSidebarBrand>
				<CCloseButton
					className="d-lg-none"
					dark
					onClick={() => dispatch({ type: 'set', sidebarShow: false })}
				/>
			</CSidebarHeader>
			<AppSidebarNav items={navigation} />
			<CSidebarFooter className="border-top d-none d-lg-flex">
				<CSidebarToggler
					onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
				/>
			</CSidebarFooter>
		</CSidebar>
	)
}

export default React.memo(AppSidebar)