import React, { useState, useEffect } from 'react';

const CookiePopup = () => {
	const [showPopup, setShowPopup] = useState(false);

	useEffect(() => {
		const acceptedCookies = localStorage.getItem('cookiesAccepted');
		if (!acceptedCookies) {
			setShowPopup(true);
		}
	}, []);

	const handleAccept = () => {
		localStorage.setItem('cookiesAccepted', 'true');
		setShowPopup(false);
	};

	if (!showPopup) {
		return null;
	}

	return (
		<div className="cookie-popup card text-center shadow-sm position-fixed bottom-0 end-0 m-3" style={{ maxWidth: '500px', zIndex: '1050' }}>
			<div className="card-body">
				<p className="card-text mb-3">
					We use cookies to improve your experience on our site. By using our site, you consent to cookies.
				</p>
				<button onClick={handleAccept} className="btn btn-primary btn-sm">
					Accept
				</button>
			</div>
		</div>
	);
};

export default CookiePopup;
