import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { CCard } from '@coreui/react';

const CategoryCard = ({ image, name, render }) => {
	return (
		<Link className='text-decoration-none text-white' to={`${render}`}>
			<CCard className='bg-dark border m-1 p-2 rounded-4'>
				<img
					src={`${process.env.REACT_APP_BACKEND_URL}${image}`}
					alt={name}
					className="rounded-4 mt-2"
					style={{ height: '180px', objectFit: 'cover', width: '100%' }}
				/>
				<div className='text-white pt-2'>
						<h6 className="mb-2 pointer">{name}</h6>
				</div>
			</CCard>
		</Link>
	);
};

CategoryCard.propTypes = {
	image: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	render: PropTypes.string.isRequired,
};

export default CategoryCard;
