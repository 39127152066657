import React from 'react'
import PropTypes from 'prop-types'

import {
	CRow,
	CCol,
	CWidgetStatsA,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilArrowBottom, cilArrowTop } from '@coreui/icons'

const WidgetsDropdown = (props) => {
	return (
		<CRow className={props.className} xs={{ gutter: 4 }}>
			<CCol sm={6} xl={4} xxl={3}>
				<CWidgetStatsA
					color="primary"
					value={
						<>
							{'12K'}
							<span className="fs-6 fw-normal">
								(-12.4% <CIcon icon={cilArrowBottom} />)
							</span>
						</>
					}
					title="Categories"
				/>
			</CCol>
			<CCol sm={6} xl={4} xxl={3}>
				<CWidgetStatsA
					color="info"
					value={
						<>
							$6.200{' '}
							<span className="fs-6 fw-normal">
								(40.9% <CIcon icon={cilArrowTop} />)
							</span>
						</>
					}
					title="Products"
				/>
			</CCol>
			<CCol sm={6} xl={4} xxl={3}>
				<CWidgetStatsA
					color="warning"
					value={
						<>
							2.49%{' '}
							<span className="fs-6 fw-normal">
								(84.7% <CIcon icon={cilArrowTop} />)
							</span>
						</>
					}
					title="Inquiries"
				/>
			</CCol>
			<CCol sm={6} xl={4} xxl={3}>
				<CWidgetStatsA
					color="danger"
					value={
						<>
							44K{' '}
							<span className="fs-6 fw-normal">
								(-23.6% <CIcon icon={cilArrowBottom} />)
							</span>
						</>
					}
					title="Contact"
				/>
			</CCol>
		</CRow>
	)
}

WidgetsDropdown.propTypes = {
	className: PropTypes.string,
	withCharts: PropTypes.bool,
}

export default WidgetsDropdown
