import React from "react";

const PaginationButton = ({ paginationData, onPageChange }) => {
	const { page, totalPages } = paginationData;

	const handlePageChange = (newPage) => {
		if (newPage > 0 && newPage <= totalPages) {
			onPageChange(newPage);
		}
	};

	return (
		<div className="d-flex justify-content-end">
			<nav aria-label="Page navigation example">
				<ul className="pagination">
					<li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
						<a
							className="page-link"
							href="#"
							aria-label="Previous"
							onClick={() => handlePageChange(page - 1)}
						>
							<span aria-hidden="true">&laquo;</span>
						</a>
					</li>
					{[...Array(totalPages)].map((_, index) => (
						<li
							key={index}
							className={`page-item ${page === index + 1 ? 'active' : ''}`}
						>
							<a
								className="page-link"
								href="#"
								onClick={() => handlePageChange(index + 1)}
							>
								{index + 1}
							</a>
						</li>
					))}
					<li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
						<a
							className="page-link"
							href="#"
							aria-label="Next"
							onClick={() => handlePageChange(page + 1)}
						>
							<span aria-hidden="true">&raquo;</span>
						</a>
					</li>
				</ul>
			</nav>
		</div>
	);
};

export default PaginationButton;
