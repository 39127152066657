import React, { useEffect, useState } from "react";
import axios from "axios";
import DynamicTable from "components/Admin/DynamicTable";

function Categories() {
	const [allCategories, setCategories] = useState([]);
	const [paginationData, setPaginationData] = useState({});

	const config = {
		headerTitle: "Categories",
		listDescription: "Here is the Categories List",
		addButtonLabel: "Add Category",
		addButtonLink: "/admin/category/add",
		editButtonLink: "/admin/category/edit",
		tableHeaders: ["#", "Category Name", "Action"],
		tableFields: ["id", "categoryName", "action"]
	};
	
	const fetchCategories = async (page = 1) => {
		try {
			const response = await axios.get(`${process.env.REACT_APP_API_URL}admin/category`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
				params: { page }
			});
			const data = response.data;
			setPaginationData(data.paginationData);

			// Format categories data
			return data.data.map(item => ({
				_id: item._id,
				categoryName: item.categoryName
			}));
		} catch (error) {
			console.error("Error fetching categories:", error);
			return [];
		}
	};

	useEffect(() => {
		const getCategories = async () => {
			const categories = await fetchCategories();
			setCategories(categories);
		};
		getCategories();
	}, []);

	const handleDelete = async (id) => {
		const confirmed = window.confirm("Are you sure you want to delete this category?");
		if (!confirmed) {
			return;
		}

		try {
			await axios.delete(`${process.env.REACT_APP_API_URL}admin/category/delete/${id}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				}
			});
			setCategories(prevCategories => prevCategories.filter(category => category._id !== id));
		} catch (error) {
			console.error("Error deleting category:", error);
		}
	};

	const onPageChange = async (page) => {
		const categories = await fetchCategories(page);
		setCategories(categories);
	};

	// Format table data for DynamicTable
	const tableData = allCategories.map((category, index) => ({
		id: (paginationData.page - 1) * paginationData.limit + (index + 1),
		categoryName: category.categoryName,
		action: category._id
	}));

	return (
		<DynamicTable
			config={config}
			tableData={tableData}
			handleDelete={handleDelete}
			paginationData={paginationData}
			onPageChange={onPageChange}
		/>
	);
}

export default Categories;
