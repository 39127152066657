import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchBar from 'components/HomeSections/SearchBar';
import DropLine from 'assets/images/icons/drop-line.svg';
import MarkerIcon from 'assets/images/marker.svg';

function Location() {
	const navigate = useNavigate();
	const [projects, setProjects] = useState([]);
	const [filteredProjects, setFilteredProjects] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');

	useEffect(() => {
		const fetchProjects = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}guest/projects`);
				const data = await response.json();
				setProjects(data.data);
				setFilteredProjects(data.data);
			} catch (error) {
				setFilteredProjects([]);
				console.error("Error fetching projects", error);
			}
		};
		fetchProjects();
	}, []);

	const handleSearch = (value) => {
		setSearchTerm(value);
		filterProjects(value);
	};

	const filterProjects = (term) => {
		const lowercasedTerm = term.toLowerCase();
		if (term === '') {
			setFilteredProjects(projects);
		} else {
			const filtered = projects.filter(project =>
				project.name.toLowerCase().includes(lowercasedTerm)
			);
			setFilteredProjects(filtered);
		}
	};

	useEffect(() => {
		const loadGoogleMapsScript = () => {
			const script = document.createElement('script');
			const userCountry = new Intl.DateTimeFormat().resolvedOptions().timeZone;
			if ([
				'Asia/Shanghai',
				'Asia/Chongqing',
				'Asia/Urumqi',
				'Asia/Harbin',
				'Asia/Hong_Kong',
				'Asia/Macau',
				'Asia/Kolkata',
				'Asia/Taipei'
			  ].includes(userCountry)
			) {
				script.src = 'http://maps.googleapis.cn/maps/api/js?key=AIzaSyB3rjPMzkRV9c9Gd6yY01gA1_OZ0_ho9t4&libraries=places';
			} else {
				script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyB3rjPMzkRV9c9Gd6yY01gA1_OZ0_ho9t4&libraries=places';
			}
			script.async = true;
			script.defer = true;
			document.body.appendChild(script);

			script.onload = () => {
				// Initialize the Google Map after the script is loaded
				initMap();
			};
		};

		const initMap = () => {
			const defaultLocations = [
				{ lat: 37.7749, lng: -122.4194, title: "Default Location 1" },
				{ lat: 34.0522, lng: -118.2437, title: "Default Location 2" }
			];

			const locations = filteredProjects.length > 0
				? filteredProjects.map(project => ({
					lat: parseFloat(project.latitude),
					lng: parseFloat(project.longitude),
					title: project.location,
					locationIcon: project.locationIcon.length > 0 ? `${process.env.REACT_APP_BACKEND_URL}${project.locationIcon}` : null, // Set the locationIcon only if it's valid
					project_id: project._id
				}))
				: defaultLocations;

			const map = new window.google.maps.Map(document.getElementById("map"), {
				zoom: 4,
				center: locations[0],
				styles: [
					{
						"elementType": "geometry",
						"stylers": [{ "color": "#212121" }]
					},
					{
						"elementType": "labels.icon",
						"stylers": [{ "visibility": "off" }]
					},
					{
						"elementType": "labels.text.fill",
						"stylers": [{ "color": "#757575" }]
					},
					{
						"elementType": "labels.text.stroke",
						"stylers": [{ "color": "#212121" }]
					},
					{
						"featureType": "administrative",
						"elementType": "geometry",
						"stylers": [{ "color": "#757575" }]
					},
					{
						"featureType": "poi",
						"elementType": "geometry",
						"stylers": [{ "color": "#424242" }]
					},
					{
						"featureType": "road",
						"elementType": "geometry",
						"stylers": [{ "color": "#2c2c2c" }]
					},
					{
						"featureType": "road.arterial",
						"elementType": "labels.text.fill",
						"stylers": [{ "color": "#787878" }]
					},
					{
						"featureType": "road.highway",
						"elementType": "geometry",
						"stylers": [{ "color": "#3c3c3c" }]
					},
					{
						"featureType": "road.highway",
						"elementType": "labels.text.fill",
						"stylers": [{ "color": "#b0b0b0" }]
					},
					{
						"featureType": "transit",
						"elementType": "geometry",
						"stylers": [{ "color": "#262626" }]
					},
					{
						"featureType": "water",
						"elementType": "geometry",
						"stylers": [{ "color": "#000000" }]
					}
				]
			});

			locations.forEach(location => {
				new window.google.maps.Marker({
					position: { lat: location.lat, lng: location.lng },
					title: location.title,
					icon: {
						url: location.locationIcon ? location.locationIcon : MarkerIcon, // Use default MarkerIcon if locationIcon is null
						scaledSize: location.locationIcon ? new window.google.maps.Size(32, 32) : null // Set scaledSize only for custom icons
					},
					map: map
				}).addListener('click', () => {
					if (location.project_id) {
						navigate('project/detail/' + location.project_id);
					}
				});
			});
		};

		if (!window.google) {
			loadGoogleMapsScript();
		} else {
			initMap();
		}
	}, [filteredProjects]);

	function debounce(func, delay) {
		let timeoutId;
		return function (...args) {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
			timeoutId = setTimeout(() => {
				func(...args);
			}, delay);
		};
	}

	return (
		<div className='container-fluid py-4' style={{ backgroundColor: '#222222' }}>
			<div className='container text-white'>
				<div className='d-flex justify-content-between py-4'>
					<div>
						<h1>Projects Location</h1>
						<img src={DropLine} className='drop-line pointer' alt="Drop Line" width={100} />
					</div>
					<div>
						<SearchBar onSearch={ debounce(handleSearch, 500) } value={searchTerm} />
					</div>
				</div>
				<div className='py-4'>
					<div id="map" className='rounded-3' style={{ height: '500px', width: '100%' }}></div>
				</div>
			</div>
		</div>
	);
}

export default Location;
