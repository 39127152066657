import React, { useEffect, useState, useCallback } from "react";
import DynamicTable from "components/Admin/DynamicTable";

const config = {
	headerTitle: "Contacts",
	listDescription: "Here is the Contact List",
	tableHeaders: ["#", "FirstName", "LastName", "Email"],
	tableFields: ["id", "firstName", "lastName", "email"],
};

function Contact() {
	const [contacts, setContacts] = useState([]);
	const [paginationData, setPaginationData] = useState({
		page: 1,
		limit: 10,
		totalPages: 0,
	});

	// Fetch contacts with pagination
	const fetchContacts = useCallback(async (page = 1) => {
		const token = localStorage.getItem('token');
		const headers = { Authorization: `Bearer ${token}` };

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}admin/contacts?page=${page}`, {
				headers,
			});
			const data = await response.json();
			if (data.status) {
				setContacts(data.data);
				setPaginationData(data.paginationData);
			} else {
				console.error('Failed to fetch contacts:', data.message);
			}
		} catch (error) {
			console.error('Error fetching contacts:', error);
		}
	}, []);

	// Fetch contacts on component mount (only once)
	useEffect(() => {
		fetchContacts();
	}, [fetchContacts]);

	// Handle page change
	const onPageChange = (page) => {
		fetchContacts(page);
	};

	const tableData = contacts.map((contact, index) => ({
		id: (paginationData.page - 1) * paginationData.limit + (index + 1),
		_id: contact._id,
		firstName: contact.firstName,
		lastName: contact.lastName,
		phone: contact.phone,
		email: contact.email,
		message: contact.message,
		action: contact._id,
	}));

	return (
		<DynamicTable
			config={config}
			tableData={tableData}
			paginationData={paginationData}
			onPageChange={onPageChange}
		/>
	);
}

export default Contact;
