import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import FilePreview from 'components/Admin/FilePreview';

const NewsHeader = () => {
	const [setting, setSetting] = useState({ heading: '', subHeading: '', headingImage: '' });
	const [file, setFile] = useState(null);

	useEffect(() => {
		const fetchSettingDetail = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}admin/setting/headers/news-page`, {
					headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
				});
				const data = await response.json();
				if (data.status) {
					setSetting(data.data);
				} else {
					console.error('Failed to fetch settings:', data.message);
				}
			} catch (error) {
				console.error('Error fetching setting:', error);
			}
		};
		fetchSettingDetail();
	}, []);

	const handleChange = (e) => setSetting({ ...setting, [e.target.name]: e.target.value });

	const handleFile = (e) => setFile(e.target.files[0]);

	const saveSetting = async (e) => {
		e.preventDefault();
		try {
			const formData = new FormData();
			formData.append('type', 'news-page');
			formData.append('heading', setting.heading);
			formData.append('subHeading', setting.subHeading);
			if (file) formData.append('headingImage', file);

			const response = await fetch(`${process.env.REACT_APP_API_URL}admin/setting/headers/store`, {
				method: 'POST',
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				body: formData
			});

			const result = await response.json();
			if (result.status) {
				let settings = JSON.parse(localStorage.getItem('settings'));
				if (settings) {
					localStorage.removeItem('settings');
				}
				toast.success('Setting saved successfully!', { position: 'top-right' });
			} else {
				toast.error('Failed to save setting.', { position: 'top-right' });
			}
		} catch (error) {
			console.error('Error saving setting:', error);
			toast.error('Failed to save setting.', { position: 'top-right' });
		}
	};

	return (
		<div className='container'>
			<h2>News Page Setting</h2>
			<form onSubmit={saveSetting}>
				<div className="mb-3">
					<label htmlFor="heading" className="form-label">Heading</label>
					<input
						type="text"
						name="heading"
						value={setting.heading}
						onChange={handleChange}
						className="form-control"
						id="heading"
						required
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="subHeading" className="form-label">Sub Heading</label>
					<textarea
						name="subHeading"
						value={setting.subHeading}
						onChange={handleChange}
						className="form-control"
						id="subHeading"
						rows={6}
						required
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="headingImage" className="form-label">File</label>
					<input type="file" name="headingImage" onChange={handleFile} className="form-control" />
					{setting.headingImage && <FilePreview path={setting.headingImage} />}
				</div>
				<button type="submit" className="btn btn-primary mb-3">Save</button>
			</form>
		</div>
	);
};

export default NewsHeader;
