import React, { useState, useEffect } from 'react';
import { CContainer, CRow, CCol } from '@coreui/react';

const Header = (props) => {
	const { page } = props;
	const [headerData, setHeaderData] = useState({
		title: '',
		subtitle: '',
		backgroundImage: '',
		youtubeUrl: ''
	});

	const updateHeaderData = (settings) => {
		if (settings && settings[page]) {
			setHeaderData({
				title: settings[page].heading || '',
				subtitle: settings[page].subHeading || '',
				backgroundImage: settings[page].headingImage
					? `${process.env.REACT_APP_BACKEND_URL}${settings[page].headingImage}`
					: '',
				youtubeUrl: settings[page].youtubeUrl || ''
			});
		}
	};

	const fetchSettings = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}guest/setting-detail`);
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			const data = await response.json();
			if (data.data) {
				const settingsObject = data.data.reduce((acc, setting) => {
					const type = setting.type.replace('-', '_');
					acc[type] = setting;
					return acc;
				}, {});

				localStorage.setItem('settings', JSON.stringify(settingsObject));
				updateHeaderData(settingsObject);
			}
		} catch (error) {
			console.error('Error fetching settings:', error);
		}
	};

	useEffect(() => {
		const storedSettings = localStorage.getItem('settings');

		if (storedSettings) {
			const settings = JSON.parse(storedSettings);
			updateHeaderData(settings);
		} else {
			fetchSettings();
		}
	}, []);

	return (
		<header
			className="header-main"
			style={{
				backgroundImage: headerData.backgroundImage ? `url(${headerData.backgroundImage})` : 'none',
				backgroundSize: 'cover',
				backgroundPosition: 'center'
			}}
		>
			<div className="header-overlay"></div>
			<CContainer className="text-center d-flex align-items-center justify-content-center header-container">
				<CRow>
					<CCol>
						<h1>{headerData.title}</h1>
						<h5 className="text-white">{headerData.subtitle}</h5>
						<div className='header-contact'>
							<div className='section-contact'>
								<p className='section-color py-2'>Head Quarter UAE</p>
								<p>+971 50 116 4472</p>
							</div>
							<div className='section-contact'>
								<p className='section-color py-2'>USA</p>
								<p>+1 908 240 9346</p>
							</div>
						</div>
					</CCol>
				</CRow>
			</CContainer>
		</header>
	);
};

export default Header;
