import axios from 'axios';
import toast from 'react-hot-toast';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FilePreview from 'components/Admin/FilePreview';

function ProjectEdit() {
	const { id } = useParams();
	const navigate = useNavigate();
	const [project, setProject] = useState({});
	const [files, setFiles] = useState([]);
	const [locationIcon, setLocationIcon] = useState(null);
	const [imageUrls, setImageUrls] = useState([]);
	const [newImageUrl, setNewImageUrl] = useState('');
	const [latitude, setLatitude] = useState('');
	const [longitude, setLongitude] = useState('');
	const [isLocationSelected, setIsLocationSelected] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const addressInputRef = useRef(null);

	useEffect(() => {
		if (id) {
			axios.get(`${process.env.REACT_APP_API_URL}admin/project/detail/${id}`, { 
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				}
			})
			.then(response => {
				setProject(response.data.data);
				setLatitude(response.data.data.latitude || '');
				setLongitude(response.data.data.longitude || '');
				setIsLocationSelected(true);
			})
			.catch(error => toast.error('Failed to fetch project details', { position: 'top-right' }));
		}
	}, [id]);

	useEffect(() => {
		const loadGoogleMapsScript = () => {
			const script = document.createElement('script');
			script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB3rjPMzkRV9c9Gd6yY01gA1_OZ0_ho9t4&libraries=places`;
			script.async = true;
			script.defer = true;
			document.body.appendChild(script);
		
			script.onload = initAutocomplete;
		};

		const initAutocomplete = () => {
			if (window.google && window.google.maps) {
			const autocomplete = new window.google.maps.places.Autocomplete(addressInputRef.current, {
				types: ['(cities)'], // Corrected: Restrict to cities
			});

			autocomplete.addListener('place_changed', () => {
				const place = autocomplete.getPlace();
				if (place.geometry) {
					setLatitude(place.geometry.location.lat());
					setLongitude(place.geometry.location.lng());
					setProject((prev) => ({
						...prev,
						location: place.formatted_address,
					}));
					setIsLocationSelected(true);
				} else {
					toast.error('Location details are not available.');
				}
			});
			}
		};

		if (!window.google) {
			loadGoogleMapsScript();
		} else {
			initAutocomplete();
		}

		// Cleanup function to remove Google Maps script when component unmounts
		return () => {
			const googleScript = document.querySelector('script[src*="maps.googleapis"]');
			if (googleScript) {
			googleScript.remove();
			}
		};
		}, []);

	const inputHandler = (e) => {
		const { name, value } = e.target;
		setProject(prev => ({
			...prev,
			[name]: value
		}));
	};

	const fileHandler = (e) => {
		const { files } = e.target;
		setFiles(Array.from(files));
	};

	const handleLocationIcon = (e) => {
		const file = e.target.files[0];
		if (file) {
			setLocationIcon(file);
		} else {
			setLocationIcon(null);
		}
	};

	const handleRemoveImage = async (index, projectId) => {
		if (index !== null && projectId !== null) {
			const confirmed = window.confirm('Are you sure you want to delete this?');

			if (confirmed) {
				try {
					const response = await fetch(`${process.env.REACT_APP_API_URL}admin/project/${projectId}/image/${index}`, {
						method: 'DELETE',
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.ok) {
						toast.success('Image deleted successfully', { position: "top-right" });
						setProject((prevProject) => ({
							...prevProject,
							filePath: prevProject.filePath.filter((_, i) => i !== index),
						}));
					} else {
						toast.error('Failed to delete the image.');
					}
				} catch (error) {
					toast.error('Error deleting image');
				}
			}
		}
	};

	const saveProject = async (e) => {
		e.preventDefault();
		
		if (!isLocationSelected) {
			toast.error('You must select a location from the location dropdown.', { position: 'top-right' });
			return;
		}

		setIsSubmitting(true);
		const formData = new FormData();
		Object.keys(project).forEach(key => {
			formData.append(key, project[key]);
		});
		formData.append('latitude', Number(latitude));
		formData.append('longitude', Number(longitude));
		if (locationIcon && locationIcon instanceof File) {
			formData.append('locationIcon', locationIcon);
		}
		files.forEach(file => {
			formData.append('files', file);
		});
		imageUrls.forEach(url => formData.append('imageUrl', url));

		try {
			if (id) {
				await axios.put(`${process.env.REACT_APP_API_URL}admin/project/update/${id}`, formData, { 
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				});
				toast.success('Project updated successfully', { position: 'top-right' });
			} else {
				await axios.post(`${process.env.REACT_APP_API_URL}admin/project/create`, formData, { 
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				});
				toast.success('Project added successfully', { position: 'top-right' });
			}
			navigate('/admin/project/index');
		} catch (error) {
			toast.error('Failed to save project', { position: 'top-right' });
		}
	};

	const addImageUrl = () => {
		if (newImageUrl.trim()) {
			setImageUrls([...imageUrls, newImageUrl]);
			setNewImageUrl(''); // Clear the input after adding
		}
	};

	const removeImageUrl = (urlToRemove) => {
		setImageUrls(imageUrls.filter(url => url !== urlToRemove));
	};

	const truncateUrl = (url, charLimit = 200) => {
		if (!url) return null;
		if (url.length <= charLimit) return url;

		return url.slice(0, charLimit) + '...';
	};
		

	return (
		<div className='container'>
			<h1>{id ? 'Edit Project' : 'Add Project'}</h1>
			<form onSubmit={saveProject}>
				<div className="mb-3">
					<label htmlFor="name" className="form-label">Name</label>
					<input
						type="text"
						name="name"
						value={project.name || ''}
						onChange={inputHandler}
						className="form-control"
						id="name"
						required
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="category" className="form-label">Category</label>
					<input
						type="text"
						name="category"
						value={project.category || ''}
						onChange={inputHandler}
						className="form-control"
						id="category"
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="ownerName" className="form-label">Owner Name</label>
					<input
						type="text"
						name="ownerName"
						value={project.ownerName || ''}
						onChange={inputHandler}
						className="form-control"
						id="ownerName"
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="moduleType" className="form-label">Module Type</label>
					<input
						type="text"
						name="moduleType"
						value={project.moduleType || ''}
						onChange={inputHandler}
						className="form-control"
						id="moduleType"
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="systemVersion" className="form-label">System Version</label>
					<input
						type="text"
						name="systemVersion"
						value={project.systemVersion || ''}
						onChange={inputHandler}
						className="form-control"
						id="systemVersion"
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="yearOfCompletion" className="form-label">Year of Completion</label>
					<input
						type="text"
						name="yearOfCompletion"
						value={project.yearOfCompletion || ''}
						onChange={inputHandler}
						className="form-control"
						id="yearOfCompletion"
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="location" className="form-label">Location</label>
					<input
						type='text'
						name="location"
						value={project.location || ''}
						onChange={inputHandler}
						className="form-control"
						id="location"
						ref={addressInputRef}
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="locationIcon" className="form-label">Location Icon</label>
					<input
						type='file'
						name="locationIcon"
						onChange={handleLocationIcon} // Use the updated handler
						className="form-control"
						id="locationIcon"
					/>
					{project.locationIcon && project.locationIcon.length > 0 && (
						<div className="d-flex flex-wrap gap-2 mt-3">
							{project.locationIcon.map((path) => (
								<FilePreview path={path} id={project._id} />
							))}
						</div>
					)}
				</div>
				<div className="mb-3">
					<label htmlFor="description" className="form-label">Description</label>
					<textarea
						name="description"
						value={project.description || ''}
						onChange={inputHandler}
						className="form-control"
						id="description"
						rows="4"
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="imageUrl" className="form-label">Image URLs</label>
					<div>
						<input
							type="text"
							value={newImageUrl}
							onChange={(e) => setNewImageUrl(e.target.value)}
							className="form-control"
							placeholder="Enter new image URL"
						/>
						<button type="button" onClick={addImageUrl} className="btn btn-primary mt-2">Add URL</button>
					</div>
					<ul className="list-group mt-3">
						{imageUrls.map((url, index) => (
							<li className="list-group-item d-flex justify-content-between align-items-center" key={index}>
								{truncateUrl(url, 100)}
								<button type="button" onClick={() => removeImageUrl(url)} className="btn btn-danger btn-sm">Remove</button>
							</li>
						))}
					</ul>
				</div>
				<div className="mb-3">
					<label htmlFor="files" className="form-label">Files</label>
					<input
						type='file'
						multiple
						onChange={fileHandler}
						className="form-control"
						id="files"
					/>
					{project.filePath && project.filePath.length > 0 && (
						<div className="d-flex flex-wrap gap-2 mt-3">
							{project.filePath.map((path, index) => (
								<FilePreview path={path} id={project._id} index={index} onRemove={handleRemoveImage} />
							))}
						</div>
					)}
				</div>
				<button type="submit" className="btn btn-primary" disabled={isSubmitting}>{id ? 'Update' : 'Create'}</button>
				<br></br>
				<br></br>
			</form>
		</div>
	);
}

export default ProjectEdit;
