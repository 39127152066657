import axios from 'axios';
import toast from 'react-hot-toast';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FilePreview from 'components/Admin/FilePreview';

function NewsEdit() {
	const { id } = useParams();
	const navigate = useNavigate();
	const [imageFiles, setImageFiles] = useState([]);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [news, setNews] = useState({
		title: "",
		description: "",
		isFeatured: false
	});

	useEffect(() => {
		const fetchNews = async () => {
			if (id) {
				try {
					const response = await fetch(`${process.env.REACT_APP_API_URL}admin/news/detail/${id}`, { 
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						}
					 });
					const data = await response.json();
					setNews({
						title: data.data.title || "",
						description: data.data.description || "",
						isFeatured: data.data.isFeatured || false
					});
					setImageFiles(data.data.imagePath || []);
				} catch (error) {
					toast.error("Failed to fetch news details", { position: "top-right" });
				}
			}
		};

		fetchNews();
	}, [id]);

	const inputHandler = (e) => {
		const { name, value, type, checked } = e.target;
		setNews(prevNews => ({
			...prevNews,
			[name]: type === 'checkbox' ? checked : value,
		}));
	};

	const handleRemoveImage = async (index, newsId) => {
		if (index !== null && newsId !== null) {
			const confirmed = window.confirm('Are you sure you want to delete this?');

			if (confirmed) {
				try {
					const response = await fetch(`${process.env.REACT_APP_API_URL}admin/news/${newsId}/image/${index}`, {
						method: 'DELETE',
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.ok) {
						console.log('Image deleted successfully.');
						toast.success('Image deleted successfully', { position: "top-right" });
						setImageFiles((prevImageFiles) =>
							prevImageFiles.filter((_, i) => i !== index)
						  );
					} else {
						console.log('Failed to delete the image.');
					}
				} catch (error) {
					console.error('Error deleting image:', error);
				}
			}
		}
	};

	const handleFileChange = (e) => {
		setImageFiles(Array.from(e.target.files));
	};

	const saveNews = async (e) => {
		e.preventDefault();
		setIsSubmitting(true);

		try {
			const formData = new FormData();

			imageFiles.forEach(file => {
				formData.append('images', file);
			});

			Object.keys(news).forEach(key => {
				formData.append(key, news[key]);
			});

			const url = id
				? `${process.env.REACT_APP_API_URL}admin/news/update/${id}`
				: `${process.env.REACT_APP_API_URL}admin/news/create`;

			const response = await axios({
				method: id ? 'put' : 'post',
				url,
				data: formData,
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
					'Content-Type': 'multipart/form-data'
				}
			});

			toast.success(response.data.message, { position: "top-right" });
			navigate("/admin/news/index");
		} catch (error) {
			console.error('Error:', error);
			toast.error("Failed to save news", { position: "top-right" });
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<div className='container'>
			<h1>{id ? 'Edit News' : 'Add News'}</h1>
			<form onSubmit={saveNews}>
				<div className="mb-3">
					<label htmlFor="title" className="form-label">Title</label>
					<input
						type="text"
						name="title"
						value={news.title}
						onChange={inputHandler}
						className="form-control"
						id="title"
						required
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="description" className="form-label">Description</label>
					<textarea
						name="description"
						value={news.description}
						onChange={inputHandler}
						className="form-control"
						id="description"
						rows={6}
						required
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="images" className="form-label">Images</label>
					<input
						type="file"
						name="images"
						multiple
						onChange={handleFileChange}
						className="form-control"
						id="images"
					/>
					{imageFiles.length > 0 && (
						<div className="d-flex flex-wrap gap-2 mt-3">
							{imageFiles.map((file, index) => (
								<div className="d-flex" key={index}>
									<FilePreview path={file} id={id} index={index} onRemove={handleRemoveImage} />
								</div>
							))}
						</div>
					)}
				</div>
				<div className="mb-3">
					<div className="form-check form-switch">
						<input
							className="form-check-input"
							type="checkbox"
							id="isFeatured"
							name="isFeatured"
							checked={news.isFeatured}
							onChange={inputHandler}
						/>
						<label className="form-check-label" htmlFor="isFeatured">
							{news.isFeatured ? 'Featured' : 'Not Featured'}
						</label>
					</div>
				</div>
				<button type="submit" className="btn btn-primary" disabled={isSubmitting}>{id ? 'Update' : 'Create'}</button>
				<br></br>
				<br></br>
			</form>
		</div>
	);
}

export default NewsEdit;
