import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import axios from 'axios';

function InquiryEdit() {
	const [inquiry, setInquiry] = useState({});
	const { id } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		const fetchInquiry = async () => {
			if (id) {
				try {
					const response = await fetch(`${process.env.REACT_APP_API_URL}admin/inquiry-detail/${id}`, { 
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						}
					 });
					const data = await response.json();
					setInquiry(data.data);
				} catch (error) {
					console.error('Error fetching inquiry:', error);
					toast.error('Failed to fetch inquiry details.');
				}
			}
		};
		fetchInquiry();
	}, [id]);

	const inputHandler = (e) => {
		const { name, value } = e.target;
		setInquiry((prev) => ({
			...prev,
			[name]: value
		}));
	};

	const checkboxHandler = (e) => {
		setInquiry((prev) => ({
			...prev,
			isConnection: e.target.checked
		}));
	};

	const saveInquiry = async (e) => {
		e.preventDefault();
		try {
			if (id) {
				await axios.put(`${process.env.REACT_APP_API_URL}admin/inquiry-update/${id}`, inquiry, { 
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				 });
				toast.success('Inquiry updated successfully!', {position : 'top-right'});
			} else {
				await axios.post(`${process.env.REACT_APP_API_URL}admin/create-inquiry`, inquiry, { 
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				 });
				toast.success('Inquiry created successfully!', {position : 'top-right'});
			}
			navigate('/admin/inquiry/index');
		} catch (error) {
			console.error('Error saving inquiry:', error);
			toast.error('Failed to save inquiry.');
		}
	};

	return (
		<div className='container'>
			<h1>{id ? 'Edit Inquiry' : 'Add Inquiry'}</h1>
			<form onSubmit={saveInquiry}>
				<div className="mb-3">
					<label htmlFor="productName" className="form-label">About Product</label>
					<input
						type="text"
						name="productName"
						value={inquiry.productName || ''}
						onChange={inputHandler}
						className="form-control"
						id="productName"
						disabled // Assuming this should remain disabled based on your initial code
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="name" className="form-label">Name</label>
					<input
						type="text"
						name="name"
						value={inquiry.name || ''}
						onChange={inputHandler}
						className="form-control"
						id="name"
						disabled // Assuming this should remain disabled based on your initial code
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="email" className="form-label">Email</label>
					<input
						type="email"
						name="email"
						value={inquiry.email || ''}
						onChange={inputHandler}
						className="form-control"
						id="email"
						disabled // Assuming this should remain disabled based on your initial code
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="phone" className="form-label">Phone</label>
					<input
						type="tel"
						name="phone"
						value={inquiry.phone || ''}
						onChange={inputHandler}
						className="form-control"
						id="phone"
						disabled // Assuming this should remain disabled based on your initial code
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="message" className="form-label">message</label>
					<textarea
						type="tel"
						name="message"
						value={inquiry.message || ''}
						onChange={inputHandler}
						className="form-control"
						id="message"
						rows={5}
						disabled // Assuming this should remain disabled based on your initial code
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="isConnection" className="form-label">Connection Status</label>
					<div className="form-check">
						<input
							type="checkbox"
							name="isConnection"
							id="isConnection"
							checked={inquiry.isConnection || false} // Set the checkbox checked status
							onChange={checkboxHandler} // Handle checkbox changes
							className="form-check-input"
						/>
						<label htmlFor="isConnection" className="form-check-label">
							Connected
						</label>
					</div>
				</div>
				<button type="submit" className="btn btn-primary mb-3">
					{id ? 'Save Changes' : 'Add Inquiry'}
				</button>
			</form>
		</div>
	);
}

export default InquiryEdit;
