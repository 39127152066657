import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from 'components/Navbar';
import Header from 'components/Header';
import Footer from 'components/Footer';
import SubscribeButton from 'components/SubscribeButton';
import ImageNotFound from 'assets/images/image_not_found.png';

function Projects() {
	const navigate = useNavigate();
	const [projects, setProjects] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');

	const fetchProjects = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}guest/projects`);
			const data = await response.json();
			setProjects(data.data);
		} catch (error) {
			setError('Failed to load projects. Please try again later.');
			console.error('Projects not found:', error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		fetchProjects();
	}, []);

	const getFileSrc = (path) => {
		const isUrl = path.startsWith('http') || path.startsWith('https');
		return isUrl ? path : `${process.env.REACT_APP_BACKEND_URL}${path}`;
	};

	return (
		<>
			<Navbar />
			<Header page='project_page' />
			<div className='container-fluid bg-black'>
				<div className='container text-white py-5'>
					<h1 className='mb-4'>Completed Projects</h1>
					{loading ? (
						<div className='text-center'>
							<div className='spinner-border text-light' role='status'>
								<span className='visually-hidden'>Loading...</span>
							</div>
						</div>
					) : error ? (
						<div className='alert alert-danger' role='alert'>
							{error}
						</div>
					) : (
						<div className='row'>
							{projects.length > 0 ? (
								projects.map((project) => (
									<div key={project._id} className='col-md-4 mb-4'>
										<div className='card bg-dark text-white'>
											{project.filePath && project.filePath.length > 0 ? (
												<img
													src={getFileSrc(project.filePath[0])}
													className='rounded-3 mb-2'
													alt={project.name}
													height={300}
												/>
											) : (
												<img
													src={ImageNotFound}
													className='rounded-3 mb-2'
													alt="Image not found"
													height={300}
												/>
											)}
											<button
												className='btn bg-green text-white'
												onClick={() => navigate(`/project/detail/${project._id}`)}
											>
												View Details
											</button>
										</div>
									</div>
								))
							) : (
								<div className='text-center text-white'>
									No projects available.
								</div>
							)}
						</div>
					)}
				</div>
			</div>
			<Footer />
			<SubscribeButton />
		</>
	);
}

export default Projects;
