import React from 'react';
import { parseISO, formatDistanceToNow } from 'date-fns';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { CCard, CCardText } from '@coreui/react';
import CalenderIcon from "assets/images/icons/calender.svg";
import DOMPurify from 'dompurify';

const formatDate = (dateStr) => {
	if (!dateStr) return '';
	try {
		const dateParsed = parseISO(dateStr);
		return formatDistanceToNow(dateParsed, { addSuffix: true });
	} catch (error) {
		console.error('Date parsing error:', error);
		return '';
	}
};

const RenderHtml = ({ htmlContent }) => {
	const sanitizedHTML = DOMPurify.sanitize(htmlContent);
  
	return (
	  <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
	);
};

const ProductCardRow = ({ render, image, name, description, partNumber, date }) => {
	return (
		<CCard className='bg-dark border p-3 rounded-4'>
			<div className='row'>
				<div className='col-md-4 d-flex justify-content-center align-items-center'>
					<img
						src={image}
						alt={name}
						className="rounded-4"
						style={{ height: '150px', objectFit: 'cover', width: '100%' }}
					/>
				</div>
				<div className='col-md-8 text-white'>
					<Link className='text-decoration-none text-white' to={`${render}`}>
						<h6 className="mb-2 pointer">{name}</h6>
						{partNumber && (
							<h6>#{partNumber}</h6>
						)}
						<RenderHtml htmlContent={description} />
					</Link>
					{date && (
						<CCardText>
							<img src={CalenderIcon} width={30} alt="Calendar Icon" />
							<span className='p-2'>{formatDate(date)}</span>
						</CCardText>
					)}
				</div>
			</div>
		</CCard>
	);
};

ProductCardRow.propTypes = {
	image: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	date: PropTypes.string,
};

export default ProductCardRow;
