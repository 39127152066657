import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import IconFilter from 'assets/images/icons/filter.svg';

const Filter = ({ categories, onFilterChange }) => {
	const { categoryName } = useParams(); // Get categoryName from the URL
	const [selectedCategories, setSelectedCategories] = useState([]);

	useEffect(() => {
		if (categoryName) {
			const categoryFromUrl = categoryName.split(',');
			setSelectedCategories([...new Set(categoryFromUrl)]);
		} else {
			setSelectedCategories([]);
		}
	}, [categoryName]);

	useEffect(() => {
		onFilterChange({ selectedCategories: selectedCategories });
	}, [selectedCategories]);

	const handleCategoryChange = (category) => {
		setSelectedCategories(prevState =>
			prevState.includes(category)
				? prevState.filter(c => c !== category)
				: [...prevState, category]
		);
	};

	const clearAllFilters = () => {
		setSelectedCategories([]);
	};

	const uniqueSubCategories = useMemo(() => 
		[...new Set(categories.map(item => item.categoryName))], [categories]
	);

	return (
		<div className="filter-container">
			<div className="filter-header">
				<h1>
					<img src={IconFilter} width={40} className="p-2" alt="Filter Icon" />
					Filter
				</h1>
				<span className="clear-all" onClick={clearAllFilters}>Clear All</span>
			</div>
			<hr className="separator" />
			<div>
				Categories
			</div>
			<hr className="separator" />
			<div className="all-categories">
				{uniqueSubCategories.map((category, index) => (
					<div key={index} className="category-item">
						<input
							type="checkbox"
							checked={selectedCategories.includes(category)}
							onChange={() => handleCategoryChange(category)}
						/>
						<span className="mx-2">{category}</span>
					</div>
				))}
			</div>
		</div>
	);
};

export default Filter;
