import React from 'react'
import CIcon from '@coreui/icons-react'

import {
	cilCalculator,
	cilPuzzle,
	cilAperture,
	cilCloudUpload,
	cilClipboard ,
	cilSpeedometer,
	cilApplications,
	cilFilterPhoto,
	cilSettings,
	cilTask,
	cilInput
} from '@coreui/icons'

import { CNavItem, CNavTitle, CNavGroup } from '@coreui/react'

const SidebarMenu = [
	{
		component: CNavItem,
		name: 'Dashboard',
		to: '/admin/dashboard',
		icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
		badge: {
			color: 'info',
			text: 'NEW',
		},
	},
	{
		component: CNavTitle,
		name: 'Components',
	},
	{
		component: CNavItem,
		name: 'Categories',
		to: '/admin/category/index',
		icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
	},
	{
		component: CNavItem,
		name: 'Category Attributes',
		to: '/admin/categories-attr/index',
		icon: <CIcon icon={cilFilterPhoto} customClassName="nav-icon" />,
	},
	{
		component: CNavItem,
		name: 'Manuals',
		to: '/admin/manuals/index',
		icon: <CIcon icon={cilCloudUpload } customClassName="nav-icon" />,
	},
	{
		component: CNavItem,
		name: 'Products',
		to: '/admin/product/index',
		icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
	},
	{
		component: CNavItem,
		name: 'Projects',
		to: '/admin/project/index',
		icon: <CIcon icon={cilAperture} customClassName="nav-icon" />,
	},
	{
		component: CNavItem,
		name: 'Static Pages',
		to: '/admin/static-pages/index',
		icon: <CIcon icon={cilInput} customClassName="nav-icon" />,
	},
	{
		component: CNavItem,
		name: 'News',
		to: '/admin/news/index',
		icon: <CIcon icon={cilClipboard } customClassName="nav-icon" />,
	},
	{
		component: CNavItem,
		name: 'Inquiries',
		to: '/admin/inquiry/index',
		icon: <CIcon icon={cilTask} customClassName="nav-icon" />,
	},
	{
		component: CNavItem,
		name: 'Contacts',
		to: '/admin/contact/index',
		icon: <CIcon icon={cilApplications} customClassName="nav-icon" />,
	},
	{
		component: CNavGroup,
		name: 'Settings',
		to: '/buttons',
		icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
		items: [
			{
				component: CNavItem,
				name: 'Meta Setting',
				to: '/admin/setting/index',
			},
			{
				component: CNavItem,
				name: 'Home Setting',
				to: '/admin/setting/home-header',
			},
			{
				component: CNavItem,
				name: 'About Setting',
				to: '/admin/setting/about-header',
			},
			{
				component: CNavItem,
				name: 'Products Setting',
				to: '/admin/setting/products-header',
			},
			{
				component: CNavItem,
				name: 'Projects Setting',
				to: '/admin/setting/projects-header',
			},
			{
				component: CNavItem,
				name: 'News Setting',
				to: '/admin/setting/news-header',
			},
		],
	  },
]

export default SidebarMenu
