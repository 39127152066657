import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CCard, CCardBody, CCardHeader, CButton, CRow, CCol, CCardTitle, CCardText, CForm, CFormInput } from '@coreui/react';
import avatar8 from 'assets/images/avatars/8.jpg'
import toast from 'react-hot-toast';

function Profile() {
	const [user, setUser] = useState(null);
	const [currentPassword, setCurrentPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmNewPassword, setConfirmNewPassword] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}admin/user`, { 
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				});
				const data = await response.json();
				setUser(data.data);
				setEmail(data.data.email);
				setPhone(data.data.phone);
			} catch (error) {
				console.error('Error fetching user data:', error);
			}
		};

		fetchData();
	}, []);

	const handlePasswordChange = async (e) => {
		e.preventDefault();
		if (newPassword !== confirmNewPassword) {
			setPasswordError('New password and confirm password do not match');
			return;
		}
		try {
			await axios.post(`${process.env.REACT_APP_API_URL}admin/user/update-password`, {
				currentPassword,
				newPassword
			}, { 
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				}
			});
			toast.success('Password updated successfully', { position: "top-right" });
			setCurrentPassword('');
			setNewPassword('');
			setConfirmNewPassword('');
		} catch (error) {
			console.error("Error updating password:", error);
			toast.error('Error updating password', { position: "top-right" });
		}
	};

	const handleProfileUpdate = async (e) => {
		e.preventDefault();
		try {
			await axios.post(`${process.env.REACT_APP_API_URL}admin/user/update-profile`, {
				email,
				phone
			}, { 
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				}
			});
			setUser(prevUser => ({ ...prevUser, email, phone }));
			toast.success('Profile updated successfully', { position: "top-right" });
		} catch (error) {
			console.error("Error updating profile:", error);
			toast.error('Error updating profile', { position: "top-right" });
		}
	};

	if (!user) {
		return <p>Loading...</p>;
	}

	return (
		<>
			<CCard>
				<CCardHeader>
					<h4>User Profile</h4>
				</CCardHeader>
				<CCardBody>
					<CRow className="align-items-center">
						<CCol md="4" className="text-center">
							<img width={100} src={user.profilePicture || avatar8}	/>
							<CCardTitle>{user.name || "User Name"}</CCardTitle>
						</CCol>
						<CCol md="8">
							<CCardText>
								<CForm onSubmit={handleProfileUpdate}>
									<CFormInput
										type="email"
										id="email"
										label="Email"
										value={email || ''}
										onChange={(e) => setEmail(e.target.value)}
										required
									/>
									<CFormInput
										type="tel"
										id="phone"
										label="Phone"
										value={phone || ''}
										onChange={(e) => setPhone(e.target.value)}
										required
									/>
									<br/>
									<CButton type="submit" color="primary" className="me-2">Update Profile</CButton>
								</CForm>
								<CForm onSubmit={handlePasswordChange} className="mt-4">
									<CFormInput
										type="password"
										id="currentPassword"
										label="Current Password"
										value={currentPassword}
										onChange={(e) => setCurrentPassword(e.target.value)}
										required
									/>
									<CFormInput
										type="password"
										id="newPassword"
										label="New Password"
										value={newPassword}
										onChange={(e) => setNewPassword(e.target.value)}
										required
									/>
									<CFormInput
										type="password"
										id="confirmNewPassword"
										label="Confirm New Password"
										value={confirmNewPassword}
										onChange={(e) => setConfirmNewPassword(e.target.value)}
										required
									/>
									{passwordError && <p className="text-danger">{passwordError}</p>}
									<br/>
									<CButton type="submit" color="primary" className="me-2">Update Password</CButton>
								</CForm>
							</CCardText>
						</CCol>
					</CRow>
				</CCardBody>
			</CCard>
		</>
	);
}

export default Profile;
