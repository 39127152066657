import toast from 'react-hot-toast';
import { Editor } from '@tinymce/tinymce-react';
import React, { useRef, useEffect, useState } from 'react';

const TinyMCEInput = ({ value, field, inputHandler }) => {
	const fileInputRef = useRef(null);
	const editorRef = useRef(null);
	const [videoState, setVideoState] = useState({
		videoUrl: '',
		videoUpload: false,
		type: ''
	});

	const typeOfUrl = () => {
		const currentPath = window.location.pathname;
		let determinedType = '';
		if (currentPath.includes('/admin/product/')) {
			determinedType = 'product';
			setVideoState(prevState => ({ ...prevState, videoUpload: true }));
		} else if (currentPath.includes('/admin/project/')) {
			determinedType = 'project';
		} else if (currentPath.includes('/admin/setting/')) {
			determinedType = 'setting';
		}
		setVideoState(prevState => ({ ...prevState, type: determinedType }));
	};

	const handleFileChange = async (event) => {
		const file = event.target.files[0];
		if (file && file.type.startsWith('video/')) {
			try {
				const formData = new FormData();
				formData.append('video', file);
				formData.append('type', videoState.type);

				const response = await fetch(`${process.env.REACT_APP_API_URL}admin/ck-upload/video`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},
					body: formData,
				});

				if (!response.ok) throw new Error('Video upload failed');

				const responseData = await response.json();
				const videoUrl = responseData.url;

				setVideoState(prevState => ({ ...prevState, videoUrl }));
				editorRef.current.insertContent(`<video controls width="100%" src="${videoUrl}"></video>`);
			} catch (error) {
				toast.error("Video upload failed: " + error.message);
			}
		}
		event.target.value = '';
	};

	const handleTinyMCEChange = (content) => {
		inputHandler(field, content);
	};

	const toBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result);
			reader.onerror = reject;
			reader.readAsDataURL(file);
		});
	};

	const customFilePickerCallback = (callback, value, meta) => {
		if (meta.filetype === 'image') {
			fileInputRef.current.click();
			fileInputRef.current.onchange = async () => {
				const file = fileInputRef.current.files[0];
				if (file) {
					const base64 = await toBase64(file);
					callback(base64, { title: file.name });
				}
				fileInputRef.current.value = '';
			};
		}
	};

	useEffect(() => {
		typeOfUrl();
		return () => {
			editorRef.current = null;
		};
	}, []);

	return (
		<div>
			<input
				type="file"
				ref={fileInputRef}
				style={{ display: 'none' }}
				accept="video/*,image/*"
				onChange={handleFileChange}
			/>

			<Editor
				apiKey='269ptza3l5c2z4zmihiynftk5396i90btg15uwct6382ffiv'
				onInit={(evt, editor) => editorRef.current = editor}
				value={value}
				onEditorChange={handleTinyMCEChange}
				init={{
					height: 400,
					menubar: false,
					plugins: 'image link media table code',
					toolbar: 'undo redo | formatselect | bold italic backcolor | \
							alignleft aligncenter alignright alignjustify | \
							bullist numlist outdent indent | removeformat | image code',
					image_title: true,
					automatic_uploads: true,
					file_picker_types: 'image',
					file_picker_callback: customFilePickerCallback,
					content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
				}}
			/>
		</div>
	);
};

export default TinyMCEInput;
