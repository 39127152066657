import axios from 'axios';
import toast from 'react-hot-toast';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function CategoriesAttrEdit() {
	const { id } = useParams();
	const navigate = useNavigate();
	const [categoriesAttr, setCategoriesAttr] = useState({
		fieldName: "",
		description: "",
		options: [] // State for options
	});
	const [newOption, setNewOption] = useState(""); // State for adding new options
	const [isSubmitting, setIsSubmitting] = useState(false);

	useEffect(() => {
		const fetchCategoriesAttr = async () => {
			if (id) {
				try {
					const response = await axios.get(`${process.env.REACT_APP_API_URL}admin/categories-attr/detail/${id}`, { 
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						}
					});
					setCategoriesAttr({
						fieldName: response.data.data.fieldName || "",
						description: response.data.data.description || "",
						options: response.data.data.options || [] // Update options
					});
				} catch (error) {
					toast.error("Failed to fetch category attr details", { position: "top-right" });
					console.error("Fetch error:", error);
				}
			}
		};

		fetchCategoriesAttr();
	}, [id]);

	const inputHandler = (e) => {
		const { name, value, type, checked } = e.target;
		setCategoriesAttr(prevAttr => ({
			...prevAttr,
			[name]: type === 'checkbox' ? checked : value,
		}));
	};

	const handleOptionChange = (e) => {
		setNewOption(e.target.value);
	};

	const addOption = () => {
		if (newOption) {
			setCategoriesAttr(prevAttr => ({
				...prevAttr,
				options: [...prevAttr.options, newOption]
			}));
			setNewOption(""); // Clear the input after adding
		}
	};

	const removeOption = (optionToRemove) => {
		setCategoriesAttr(prevAttr => ({
			...prevAttr,
			options: prevAttr.options.filter(option => option !== optionToRemove)
		}));
	};

	const saveCategoryAttr = async (e) => {
		e.preventDefault();
		setIsSubmitting(true);
	
		try {
			const data = {
				fieldName: categoriesAttr.fieldName,
				description: categoriesAttr.description,
				options: categoriesAttr.options
			};
	
			const url = id
				? `${process.env.REACT_APP_API_URL}admin/categories-attr/update/${id}`
				: `${process.env.REACT_APP_API_URL}admin/categories-attr/create`;
	
			const method = id ? 'put' : 'post';
	
			await axios({
				method,
				url,
				data,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${localStorage.getItem('token')}`
				}
			});
	
			toast.success(id ? 'Categories attr updated successfully' : 'Categories attr added successfully', { position: 'top-right' });
			navigate("/admin/categories-attr/index");
		} catch (error) {
			console.error('Error:', error);
			toast.error("Failed to save categories attr", { position: "top-right" });
		} finally {
			setIsSubmitting(false);
		}
	};
	
	return (
		<div className='container'>
			<h1>{id ? 'Edit Cateogory Attr' : 'Add Cateogory Attr'}</h1>
			<form onSubmit={saveCategoryAttr}>
				<div className="mb-3">
					<label htmlFor="fieldName" className="form-label">Field Name</label>
					<input
						type="text"
						name="fieldName"
						value={categoriesAttr.fieldName}
						onChange={inputHandler}
						className="form-control"
						id="fieldName"
						required
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="options" className="form-label">Options</label>
					<div>
						<input
							type="text"
							value={newOption}
							onChange={handleOptionChange}
							className="form-control"
							placeholder="Enter new option"
						/>
						<button type="button" onClick={addOption} className="btn btn-primary mt-2">Add Option</button>
					</div>
					<ul className="list-group mt-3">
						{categoriesAttr.options.map((option, index) => (
							<li className="list-group-item d-flex justify-content-between align-items-center" key={index}>
								{option}
								<button type="button" onClick={() => removeOption(option)} className="btn btn-danger btn-sm">Remove</button>
							</li>
						))}
					</ul>
				</div>
				<div className="mb-3">
					<label htmlFor="description" className="form-label">Description</label>
					<textarea
						name="description"
						value={categoriesAttr.description}
						onChange={inputHandler}
						className="form-control"
						id="description"
						rows={6}
						required
					/>
				</div>
				<button type="submit" className="btn btn-primary my-3" disabled={isSubmitting}>{id ? 'Update' : 'Create'}</button>
			</form>
		</div>
	);
}

export default CategoriesAttrEdit;
