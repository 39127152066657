import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from 'components/Navbar';
import Header from 'components/Header';
import Footer from 'components/Footer';
// import HeaderImage from 'assets/images/background-image/about-bg.webp';

function StaticPage() {
	const [staticData, setStaticData] = useState({});
	const [loading, setLoading] = useState(true);
	const { slug } = useParams();

	useEffect(() => {
		const fetchPageDetail = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}guest/static-page/${slug}`);
				const data = await response.json();
				setStaticData(data.data);
			} catch (error) {
				console.error('Page not found:', error);
			} finally {
				setLoading(false);
			}
		};

		if (slug) {
			fetchPageDetail();
		}
	}, [slug]);

	return (
		<>
			<Navbar />
			<Header page='home_page' />
			<div className='container-fluid bg-black pb-3'>
				<div className='container text-white py-3'>
					<h5>{staticData.heading}</h5>
					<p>{loading ? 'Loading content...' : staticData.content}</p>
				</div>
			</div>
			<Footer />
		</>
	);
}

export default StaticPage;
