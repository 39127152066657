import React, { useEffect, useState } from "react";
import axios from "axios";
import DynamicTable from "components/Admin/DynamicTable";

const config = {
	headerTitle: "Categories Attributes",
	listDescription: "Here is the Categories Attributes",
	addButtonLabel: "Add Attribute",
	addButtonLink: "/admin/categories-attr/add",
	editButtonLink: "/admin/categories-attr/edit",
	tableHeaders: ["#", "Field Name", "Options", "Action"],
	tableFields: ["id", "fieldName", "options", "action"],
};

function CategoriesAttr() {
	const [categoriesAttr, setCategoriesAttr] = useState([]);
	const [paginationData, setPaginationData] = useState({});

	const fetchData = async (page = 1) => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}admin/categories-attr/getattr?page=${page}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			});
			const data = await response.json();
			if (data.status) {
				setPaginationData(data.paginationData);
				setCategoriesAttr(data.data);
			} else {
				console.error("Unexpected response format or status is false:", data);
			}
		} catch (error) {
			console.error("Error fetching fields:", error);
			setCategoriesAttr([]); // Reset state on error
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const onPageChange = async (page) => {
		await fetchData(page);
	};

	const handleDelete = async (id) => {
		if (!window.confirm("Are you sure you want to delete this field?")) {
			return;
		}

		try {
			await axios.delete(`${process.env.REACT_APP_API_URL}admin/categories-attr/delete/${id}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			});
			setCategoriesAttr((prevAttr) => prevAttr.filter((fieldItem) => fieldItem._id !== id));
		} catch (error) {
			console.error("Error deleting field:", error);
		}
	};

	const tableData = categoriesAttr.map((fieldItem, index) => ({
		id: (paginationData.page - 1) * paginationData.limit + (index + 1),
		fieldName: fieldItem.fieldName,
		options: fieldItem.options ? fieldItem.options.join(", ") : "No options",
		action: fieldItem._id,
	}));

	return (
		<DynamicTable
			config={config}
			tableData={tableData}
			handleDelete={handleDelete}
			paginationData={paginationData}
			onPageChange={onPageChange}
		/>
	);
}

export default CategoriesAttr;
