import Slider from 'react-slick';
import { CContainer } from '@coreui/react';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import ProductCard from 'components/ProductCard';
import DropLine from 'assets/images/icons/drop-line.svg';
import ProductImg from 'assets/images/maxtron-project-5.webp';
import BtnLeft from 'assets/images/icons/left-move-btn.svg';
import BtnRight from 'assets/images/icons/right-move-btn.svg';
import FeaturedProductBG from 'assets/images/background-image/home-featured-product-bg.webp';
import './sections.css';

const FeaturedProducts = () => {
	const [products, setProducts] = useState([]);
	const sliderRef = useRef(null);
	const navigate = useNavigate();

	const truncateDescription = (description, wordLimit = 15) => {
		if (!description)
			return null;
		const words = description.split(' ');
		if (words.length <= wordLimit)
			return description;
		return words.slice(0, wordLimit).join(' ') + '...';
	};

	const fetchProduct = useCallback(async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}guest/products?isFeatured=true`);
			const data = await response.json();
			const isFeatured = data.data;
			if (isFeatured) {
				const transformedData = isFeatured.map(product => ({
					id: product._id,
					image: product.imagePath.length > 0 ? `${process.env.REACT_APP_BACKEND_URL}${product.imagePath[0]}` : ProductImg,
					name: product.name,
					partNumber: product.partNumber,
					description: truncateDescription(product.description)
				}));
				setProducts(transformedData);
			}
		} catch (error) {
			console.error('Error fetching products:', error);
		}
	}, []);

	useEffect(() => {
		fetchProduct();
	}, [fetchProduct]);

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 2,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};

	const bgStyle = {
		backgroundImage: `url(${FeaturedProductBG})`
	};

	return (
		<div className="product-container pt-4" id="products" style={bgStyle}>
			<CContainer>
				<div className="d-flex justify-content-between align-items-center mb-3">
					<div>
						<h1 className="text-white">Featured Products</h1>
						<img src={DropLine} className="drop-line" alt="Decorative drop line" width={100} />
					</div>
					<button 
						className="bg-pink px-3 py-2 text-white border-0 rounded" 
						onClick={() => navigate('/products')}
					>
						View All <span className="arrow-icon">→</span>
					</button>
				</div>
				<div className="pt-3">
					<Slider {...settings} ref={sliderRef}>
						{products.map((product) => (
							<div className="px-2" key={product.id}>
								<ProductCard
									image={product.image}
									name={product.name}
									partNumber={product.partNumber}
									render={"/product/detail/"+product.id}
								/>
							</div>
						))}
					</Slider>
				</div>
				<div className="scroller-slider-controls">
					<img 
						onClick={() => sliderRef.current.slickPrev()} 
						src={BtnLeft} 
						width={40} 
						alt="Previous slide" 
						className="img-fluid pointer" 
					/>
					<img 
						onClick={() => sliderRef.current.slickNext()} 
						src={BtnRight} 
						width={40} 
						alt="Next slide" 
						className="img-fluid pointer" 
					/>
				</div>
			</CContainer>
		</div>
	);
};

export default React.memo(FeaturedProducts);
