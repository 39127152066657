import React from 'react';
import { useNavigate } from 'react-router-dom';

const SubscribeButton = () => {
    const navigate = useNavigate();
    const buttonStyle = {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        zIndex: 1000,
    };

    const storedSettings = localStorage.getItem('settings');
    let sideFixedButton = 'Contact Us';
    let sideFixedButtonUrl = '/contact';

    if (storedSettings) {
        const settings = JSON.parse(storedSettings);
        sideFixedButton = settings['home_page']?.sideFixedButton || sideFixedButton;
        sideFixedButtonUrl = settings['home_page']?.sideFixedButtonUrl || sideFixedButtonUrl;
    }

    return (
        <button
            className="bg-pink px-3 py-2 text-white"
            onClick={() => navigate(sideFixedButtonUrl)}
            style={buttonStyle}
        >
            {sideFixedButton}
        </button>
    );
};

export default SubscribeButton;
