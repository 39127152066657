import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CContainer, CSpinner } from '@coreui/react'

import Dashboard from 'views/admin/dashboard/Dashboard';

import ContactIndex from 'views/admin/contact/index';
import ContactEdit from 'views/admin/contact/edit';

import CategoryIndex from 'views/admin/categories/index';
import CategoryEdit from 'views/admin/categories/edit';

import InquiryIndex from 'views/admin/inquiries/index';
import InquiryEdit from 'views/admin/inquiries/edit';

import NewsIndex from 'views/admin/news/index';
import NewsEdit from 'views/admin/news/edit';

import ManualsIndex from 'views/admin/manuals/index';
import ManualsEdit from 'views/admin/manuals/edit';

import ProductIndex from 'views/admin/products/index';
import ProductEdit from 'views/admin/products/edit';

import CategoriesAttr from 'views/admin/categoriesAttr/index';
import CategoriesAttrEdit from 'views/admin/categoriesAttr/edit';

import ProjectIndex from 'views/admin/projects/index';
import ProjectEdit from 'views/admin/projects/edit';

import StaticPageIndex from 'views/admin/static/index';
import StaticPageEdit from 'views/admin/static/edit';

import MetaSetting from 'views/admin/settings/Meta';
import HomePageSetting from 'views/admin/settings/HomeSetting';
import AboutPageSetting from 'views/admin/settings/AboutSetting';
import ProductPageSetting from 'views/admin/settings/ProductSetting';
import ProjectPageSetting from 'views/admin/settings/ProjectSetting';
import NewsPageSetting from 'views/admin/settings/NewsSetting';

// import UsersList from 'views/admin/users/index';
import UserProfile from 'views/admin/profile/index';

const AppContent = () => {
	return (
		<CContainer className="px-4">
			<Suspense fallback={<CSpinner color="primary" />}>
				<Routes>
					<Route path='/' element={<Dashboard /> } />
					<Route path='/dashboard' element={<Dashboard /> } />

					<Route path='/contact/index' element={<ContactIndex /> } />
					<Route path='/contact/edit/:id' element={<ContactEdit /> } />
					<Route path='/contact/add' element={<ContactEdit /> } />

					<Route path='/category/index' element={<CategoryIndex /> } />
					<Route path='/category/edit/:id' element={<CategoryEdit /> } />
					<Route path='/category/add' element={<CategoryEdit /> } />

					<Route path='/inquiry/index' element={<InquiryIndex /> } />
					<Route path='/inquiry/edit/:id' element={<InquiryEdit /> } />
					<Route path='/inquiry/add' element={<InquiryEdit /> } />

					<Route path='/news/index' element={<NewsIndex /> } />
					<Route path='/news/edit/:id' element={<NewsEdit /> } />
					<Route path='/news/add' element={<NewsEdit /> } />

					<Route path='/manuals/index' element={<ManualsIndex /> } />
					<Route path='/manual/edit/:id' element={<ManualsEdit /> } />
					<Route path='/manual/add' element={<ManualsEdit /> } />

					<Route path='/product/index' element={<ProductIndex /> } />
					<Route path='/product/edit/:id' element={<ProductEdit /> } />
					<Route path='/product/add' element={<ProductEdit /> } />
					
					<Route path='/categories-attr/index' element={<CategoriesAttr /> } />
					<Route path='/categories-attr/edit/:id' element={<CategoriesAttrEdit /> } />
					<Route path='/categories-attr/add' element={<CategoriesAttrEdit /> } />

					<Route path='/project/index' element={<ProjectIndex /> } />
					<Route path='/project/edit/:id' element={<ProjectEdit /> } />
					<Route path='/project/add' element={<ProjectEdit /> } />

					<Route path='/static-pages/index' element={<StaticPageIndex /> } />
					<Route path='/static-page/edit/:id' element={<StaticPageEdit /> } />
					<Route path='/static-page/add' element={<StaticPageEdit /> } />

					<Route path='/setting/index' element={<MetaSetting /> } />
					<Route path='/setting/home-header' element={<HomePageSetting /> } />
					<Route path='/setting/about-header' element={<AboutPageSetting /> } />
					<Route path='/setting/products-header' element={<ProductPageSetting /> } />
					<Route path='/setting/projects-header' element={<ProjectPageSetting /> } />
					<Route path='/setting/news-header' element={<NewsPageSetting /> } />

					<Route path='/profile' element={<UserProfile /> } />

					<Route path="/" element={<Navigate to="admin/dashboard" replace />} />
				</Routes>
			</Suspense>
		</CContainer>
	)
}

export default React.memo(AppContent)
