import React, { useEffect, useState } from "react";
import axios from "axios";
import DynamicTable from "components/Admin/DynamicTable";

const config = {
	headerTitle: "Static Pages",
	listDescription: "Here is the pages List",
	addButtonLabel: "Add Page",
	addButtonLink: "/admin/static-page/add",
	editButtonLink: "/admin/static-page/edit",
	tableHeaders: ["#", "Heading", "Slug", "Action"],
	tableFields: ["id", "heading", "slug", "action"],
};

function StaticPages() {
	const [staticPages, setStaticPages] = useState([]);
	const [paginationData, setPaginationData] = useState({});

	const fetchData = async (page = 1) => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}admin/static-pages?page=${page}`,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				}
			);
			const data = await response.json();
			if (data.status) {
				setPaginationData(data.paginationData);
				setStaticPages(data.data);
			} else {
				console.error("Unexpected response format or status is false:", data);
			}
		} catch (error) {
			console.error("Error fetching static pages:", error);
			setStaticPages([]);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const onPageChange = async (page) => {
		await fetchData(page);
	};

	const handleDelete = async (id) => {
		if (!window.confirm("Are you sure you want to delete this page?")) {
			return;
		}

		try {
			await axios.delete(`${process.env.REACT_APP_API_URL}admin/static-page/delete/${id}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			});
			setStaticPages((prevStaticPages) => prevStaticPages.filter((page) => page._id !== id));
		} catch (error) {
			console.error("Error deleting static page:", error);
		}
	};

	const tableData = staticPages.map((page, index) => ({
		id: (paginationData.page - 1) * paginationData.limit + (index + 1),
		heading: page.heading,
		slug: (
			<a href={`/static/${page.slug}`} style={{ color: 'blue', textDecoration: 'none' }}>
				{page.slug}
			</a>
		),
		action: page._id,
	}));

	return (
		<DynamicTable
			config={config}
			tableData={tableData}
			handleDelete={handleDelete}
			paginationData={paginationData}
			onPageChange={onPageChange}
		/>
	);
}

export default StaticPages;
