import React from 'react';
import PropTypes from 'prop-types';

const FilePreview = ({ path, id = null, index = null, onRemove }) => {
	if (typeof path !== 'string' || !path) {
		return <div> .... path</div>;
	}

	const fileExtension = path.split('.').pop().toLowerCase();
	const isImage = ['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileExtension);
	const isDocument = ['pdf', 'doc', 'docx', 'ppt', 'pptx'].includes(fileExtension);
	const isVideo = fileExtension === 'mp4';

	// Check if the path is a URL or a relative path
	const isUrl = path.startsWith('http') || path.startsWith('https');
	const fileSrc = isUrl ? path : `${process.env.REACT_APP_BACKEND_URL}${path}`;

	return (
		<div className="position-relative d-flex justify-content-center align-items-center" style={{ width: '100px', height: '100px', border: '1px solid #ddd', borderRadius: '4px' }}>
			{isImage ? (
				<img
					src={fileSrc}
					className="img-thumbnail"
					alt={`Preview`}
					data={index}
					data-id={id}
					style={{ maxWidth: '100px', maxHeight: '100px' }}
				/>
			) : isVideo ? (
				<video
					src={fileSrc}
					className="img-thumbnail"
					alt={`Preview`}
					data={index}
					data-id={id}
					style={{ maxWidth: '150px', maxHeight: '100px' }}
					controls
				>
					Your browser does not support the video tag.
				</video>
			) : isDocument ? (
				<div className="text-center">
					<i className="fas fa-file-alt" style={{ fontSize: '24px', color: '#aaa' }}></i>
					<span className="d-block mt-1" style={{ fontSize: '12px' }}>{fileExtension.toUpperCase()}</span>
				</div>
			) : (
				<div className="text-center">
					<i className="fas fa-file" style={{ fontSize: '24px', color: '#aaa' }}></i>
				</div>
			)}

			{id !== null && index != null && (
				<button
					type="button"
					onClick={() => onRemove(index, id)}
					className="btn btn-danger btn-sm text-white mx-3 position-absolute"
					style={{ top: '-10px', right: '-10px' }}
				>
					x
				</button>
			)}
		</div>
	);
};

FilePreview.propTypes = {
	path: PropTypes.string.isRequired,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	index: PropTypes.number,
	onRemove: PropTypes.func, // Passing the onRemove function prop
};

export default FilePreview;
