import React from 'react';
import ImgSearch from 'assets/images/icons/search.svg';

const SearchBar = ({ onSearch }) => {
	const getStyles = () => {
		const isSmallDevice = window.innerWidth <= 767; // Adjust the breakpoint as needed

		return {
			searchbarStyle: {
				backgroundColor: '#303335',
				borderRadius: '25px',
				padding: '5px 15px',
				width: isSmallDevice ? '100%' : '500px',
				maxWidth: '500px',
				height: '55px',
				display: 'flex',
				alignItems: 'center',
				border: 'none',
			},
			inputStyle: {
				backgroundColor: '#303335',
				color: 'white',
				border: 'none',
				outline: 'none',
				padding: isSmallDevice ? '8px' : '10px',
				marginLeft: isSmallDevice ? '8px' : '10px',
				flex: 1,
			},
			iconStyle: {
				color: 'white',
				marginRight: isSmallDevice ? '8px' : '10px',
			},
		};
	};

	const handleSearch = (e) => {
		const { value } = e.target;
		onSearch(value);
	};

	const styles = getStyles();

	return (
		<div style={styles.searchbarStyle}>
			<img src={ImgSearch} width={25} alt="Search" style={styles.iconStyle} />
			<input
				type="text"
				placeholder="Enter search"
				onChange={handleSearch}
				style={styles.inputStyle}
			/>
		</div>
	);
};

export default SearchBar;
