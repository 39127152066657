import React, { useEffect, useState, useCallback } from "react";
import DynamicTable from "components/Admin/DynamicTable";

const config = {
	headerTitle: "Inquiry",
	listDescription: "Here is the Inquiry List",
	editButtonLink: "/admin/inquiry/edit",
	tableHeaders: ["#", "Name", "Email", "Product", "Selected Attr", "Message"],
	tableFields: ["id", "name", "email", "productName", "selectedAttribute", "message"],
};

function Inquiry() {
	const [inquiries, setInquiries] = useState([]);
	const [paginationData, setPaginationData] = useState({
		page: 1,
		limit: 10,
		totalPages: 0,
	});

	// Fetch inquiries with pagination
	const fetchInquiries = useCallback(async (page = 1) => {
		const token = localStorage.getItem('token');
		const headers = { Authorization: `Bearer ${token}` };

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}admin/inquiries?page=${page}`, {
				headers,
			});
			const data = await response.json();
			if (data.status) {
				setInquiries(data.data);
				setPaginationData(data.paginationData);
			} else {
				console.error('Failed to fetch inquiries:', data.message);
			}
		} catch (error) {
			console.error('Error fetching inquiries:', error);
		}
	}, []);

	// Fetch inquiries on component mount
	useEffect(() => {
		fetchInquiries();
	}, [fetchInquiries]);

	// Handle page change
	const onPageChange = (page) => {
		fetchInquiries(page);
	};

	const formatSelectedAttribute = (attributesString) => {
		try {
			const attributes = JSON.parse(attributesString);

			return Object.entries(attributes)
			.map(([key, value]) => `${key} : ${value}`)
			.join(', ');
		} catch (error) {
			console.error('Error parsing selectedAttribute:', error);
			return 'Null';
		}
	};

	// Format table data
	const tableData = inquiries.map((inquiry, index) => ({
		id: (paginationData.page - 1) * paginationData.limit + (index + 1),
		_id: inquiry._id,
		name: inquiry.name,
		email: inquiry.email,
		phone: inquiry.phone,
		productName: (
			<a href={`/product/detail/${inquiry.productID}`} target="_blank">
			  {inquiry.productName}
			</a>
		  ),
		selectedAttribute: formatSelectedAttribute(inquiry.selectedAttribute),
		message: inquiry.message,
		// connection: inquiry.isConnection ? "Connected" : "Not connected",
		action: inquiry._id,
	}));

	return (
		<DynamicTable
			config={config}
			tableData={tableData}
			paginationData={paginationData}
			onPageChange={onPageChange}
		/>
	);
}

export default Inquiry;
