import React, { useEffect, useState, useCallback } from 'react';
import { CContainer } from '@coreui/react';
import { useNavigate } from 'react-router-dom';
import ProductCard from 'components/ProductCard';
import DropLine from 'assets/images/icons/drop-line.svg';

const truncateDescription = (description, wordLimit = 15) => {
	if (!description)
		return null;
	const words = description.split(' ');
	if (words.length <= wordLimit)
		return description;
	return words.slice(0, wordLimit).join(' ') + '...';
};

const News = () => {
	const navigate = useNavigate();
	const [featuredNews, setFeaturedNews] = useState([]);

	const fetchFeaturedNews = useCallback(async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}guest/news?isFeatured=true`);
			const data = await response.json();
			setFeaturedNews(data.data);
		} catch (error) {
			console.error('Featured news not found:', error);
		}
	}, []);

	useEffect(() => {
		fetchFeaturedNews();
	}, [fetchFeaturedNews]);

	return (
		<div className="bg-black py-5" id="news">
			<CContainer>
				<div className="d-flex justify-content-between align-items-center mb-4">
					<div>
						<h1 className="text-white">News</h1>
						<img src={DropLine} alt="Drop Line" width={50} />
					</div>
					<button 
						className="bg-pink text-white px-3 py-2"
						onClick={() => navigate('/news')}
					>
						View All <span className="arrow-icon">→</span>
					</button>
				</div>
				<div className="row pt-2">
					{featuredNews.map((news) => (
						<div className="col-md-3 mb-4" key={news._id}>
							<ProductCard
								id={news._id}
								image={`${process.env.REACT_APP_BACKEND_URL}${news.imagePath[0]}`}
								name={news.title}
								description={truncateDescription(news.description)}
								date={news.updatedAt}
								render={`/news/detail/${news._id}`}
							/>
						</div>
					))}
				</div>
			</CContainer>
		</div>
	);
};

export default React.memo(News);
