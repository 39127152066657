import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from 'react-hot-toast';

function Meta() {
	const [metaData, setMetaData] = useState({
		id: null,
		metaTitle: '',
		metaDescription: '',
		metaKeyword: ''
	});
	const [loading, setLoading] = useState(false);

	const headers = {
		Authorization: `Bearer ${localStorage.getItem('token')}`,
	};

	const handleChange = (e) => {
		const { id, value } = e.target;
		setMetaData(prevState => ({
			...prevState,
			[id]: value
		}));
	};

	const fetchSettingData = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}admin/setting/meta/getdetail`, { headers });
			const data = await response.json();
			if (data.data) {
				setMetaData(prevState => ({
					...prevState,
					...data.data,
					id: data.data._id // Assuming _id is used as the ID field
				}));
			} else {
				console.error('Unexpected API response structure:', response.data);
			}
		} catch (error) {
			console.error('Setting not found', error);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
	
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_URL}admin/setting/meta/store`,
				metaData,
				{ headers }
			);
	
			if (response.data && response.data.data) {
				let settings = JSON.parse(localStorage.getItem('settings'));
				if (settings) {
					localStorage.removeItem('settings');
				}
				toast.success('Settings saved successfully!', { position: 'top-right' });
				setMetaData(prevState => ({
					...prevState,
					id: response.data.data._id 
				}));
			} else {
				throw new Error('Invalid response data');
			}
		} catch (error) {
			console.error('Error saving settings:', error);
			toast.error('Failed to save settings. Please try again.', { position: 'top-right' });
		} finally {
			setLoading(false);
		}
	};
	

	useEffect(() => {
		fetchSettingData();
	}, []);

	return (
		<div className="container mt-4">
			<h2>Meta Settings</h2>
			<form className="mb-4" onSubmit={handleSubmit}>
				<div className="mb-3">
					<label htmlFor="metaTitle" className="form-label">Meta Title</label>
					<input
						type="text"
						className="form-control"
						id="metaTitle"
						value={metaData.metaTitle}
						onChange={handleChange}
						required
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="metaDescription" className="form-label">Meta Description</label>
					<textarea
						className="form-control"
						id="metaDescription"
						rows={5}
						value={metaData.metaDescription}
						onChange={handleChange}
						required
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="metaKeyword" className="form-label">Meta Keywords</label>
					<textarea
						className="form-control"
						id="metaKeyword"
						rows={5}
						value={metaData.metaKeyword}
						onChange={handleChange}
						required
					/>
				</div>
				<button type="submit" className="btn btn-primary" disabled={loading}>
					{loading ? 'Saving...' : 'Save Settings'}
				</button>
			</form>
		</div>
	);
}

export default Meta;
