import React, { useEffect, useState } from 'react';
import { CContainer } from '@coreui/react';
import { useNavigate } from 'react-router-dom';
import DropLine from 'assets/images/icons/drop-line.svg';
import DefaultImgOne from 'assets/images/maxtron-project-1.webp';
import DefaultImgTwo from 'assets/images/maxtron-project-2.webp';
import DefaultImgThree from 'assets/images/maxtron-project-3.webp';
import DefaultImgFour from 'assets/images/maxtron-project-4.webp';
import DefaultImgFive from 'assets/images/maxtron-project-5.webp';
import './sections.css';

function Projects() {
	const navigate = useNavigate();
	const [featuredData, setFeaturedData] = useState(null);

	const updateFeaturedData = (settings) => {
		const homePageSettings = settings.home_page || {};

		setFeaturedData({
			featuredProjectTopRightFile: `${process.env.REACT_APP_BACKEND_URL}${homePageSettings.featuredProjectTopRightFile || ''}` || DefaultImgOne,
			featuredProjectBottomRightFile: `${process.env.REACT_APP_BACKEND_URL}${homePageSettings.featuredProjectBottomRightFile || ''}` || DefaultImgTwo,
			featuredProjectCenterFile: `${process.env.REACT_APP_BACKEND_URL}${homePageSettings.featuredProjectCenterFile || ''}` || DefaultImgThree,
			featuredProjectTopLeftFile: `${process.env.REACT_APP_BACKEND_URL}${homePageSettings.featuredProjectTopLeftFile || ''}` || DefaultImgFour,
			featuredProjectBottomLeftFile: `${process.env.REACT_APP_BACKEND_URL}${homePageSettings.featuredProjectBottomLeftFile || ''}` || DefaultImgFive,

			featuredProjectTopRightText: homePageSettings.featuredProjectTopRightText || '',
			featuredProjectBottomRightText: homePageSettings.featuredProjectBottomRightText || '',
			featuredProjectCenterText: homePageSettings.featuredProjectCenterText || '',
			featuredProjectTopLeftText: homePageSettings.featuredProjectTopLeftText || '',
			featuredProjectBottomLeftText: homePageSettings.featuredProjectBottomLeftText || '',
		});
	};

	const fetchSettings = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}guest/setting-detail`);
			const data = await response.json();
			if (data.data) {
				const settingsObject = data.data.reduce((acc, setting) => {
					const type = setting.type.replace('-', '_');
					acc[type] = setting;
					return acc;
				}, {});

				localStorage.setItem('settings', JSON.stringify(settingsObject));
				updateFeaturedData(settingsObject);
			}
		} catch (error) {
			console.error('Error fetching settings:', error);
		}
	};

	useEffect(() => {
		const storedSettings = localStorage.getItem('settings');
		if (storedSettings) {
			const settings = JSON.parse(storedSettings);
			updateFeaturedData(settings);
		} else {
			fetchSettings();
		}
	}, []);

	const renderMedia = (filePathUrl, fallbackImage, altText, fileMidText) => {
		if (filePathUrl && filePathUrl.endsWith('.mp4')) {
			return (
				<div className="mb-3">
					<video controls autoPlay muted loop style={{ width: '100%', maxHeight: '300px' }} alt={altText}>
						<source src={filePathUrl} type="video/mp4" />
					</video>
				</div>
			);
		} else {
			return (
				<div className='mid-text-relative'>
					<div className='mid-text-absolute'>
						<h4 className='text-white'>{fileMidText ? fileMidText : ''}</h4>
					</div>
					<div>
						<img className="rounded-image mb-3" src={filePathUrl || fallbackImage} alt={altText} />
					</div>
				</div>
			);
		}
	};

	return (
		<div className='projects-container bg-black' id='projects'>
			<CContainer>
				<div className="d-flex justify-content-between align-items-center mb-4">
					<div>
						<h1 className='text-white'>MAXTRON Lighting Projects</h1>
						<img src={DropLine} className='drop-line' alt="Drop Line" width={100} />
					</div>
					<button
						className="bg-pink px-3 py-2 text-white"
						onClick={() => navigate('/projects')}
					>
						View All <span className="arrow-icon">→</span>
					</button>
				</div>
				<div className="row">
					<div className='d-flex justify-content-between pt-3'>
						<div className="col-md-3">
							<div className='d-flex flex-column align-items-center'>
								{renderMedia(featuredData?.featuredProjectTopLeftFile, DefaultImgOne, 'Project 1', featuredData?.featuredProjectTopLeftText)}
								{renderMedia(featuredData?.featuredProjectBottomLeftFile, DefaultImgTwo, 'Project 2', featuredData?.featuredProjectBottomLeftText)}
							</div>
						</div>

						<div className="col-md-6 d-flex flex-column align-items-center position-relative">
							<div className="image-container">
								{renderMedia(featuredData?.featuredProjectCenterFile, DefaultImgThree, 'Project 3', featuredData?.featuredProjectCenterText)}
							</div>
							<div className="overlay">
								{/* <button className="overlay-button">Fair Festival</button> */}
							</div>
						</div>

						<div className="col-md-3">
							<div className='d-flex flex-column'>
								{featuredData?.featuredProjectTopRightFile && (
									<>
										{featuredData.featuredProjectTopRightFile.endsWith('.mp4') ? (
										<div className="mb-3">
											<video controls autoPlay muted style={{ width: '100%', maxHeight: '300px' }}>
											<source src={featuredData.featuredProjectTopRightFile} type="video/mp4" />
											</video>
										</div>
										) : (
										<div className='mid-text-relative'>
											<div className='mid-text-right-absolute'>
												<h4 className='text-white'>{featuredData?.featuredProjectTopRightText}</h4>
											</div>
											<div>
												<img className="rounded-image float-end mb-3" src={featuredData.featuredProjectTopRightFile || DefaultImgFive} />
											</div>
										</div>
										)}
									</>
								)}

								{featuredData?.featuredProjectBottomRightFile && (
									<div className="mid-text-relative">
										{featuredData.featuredProjectBottomRightFile.endsWith('.mp4') ? (
										<div className="mb-3">
											<video controls autoPlay muted style={{ width: '100%', maxHeight: '300px' }}>
											<source src={featuredData.featuredProjectBottomRightFile} type="video/mp4" />
											</video>
										</div>
										) : (
										<>
											<div className='mid-text-right-absolute'>
												<h4 className='text-white'>{featuredData?.featuredProjectBottomRightText}</h4>
											</div>
											<div>
												<img className="rounded-image float-end mb-3" src={featuredData.featuredProjectBottomRightFile || DefaultImgFive} />
											</div>
										</>
										)}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</CContainer>
			<br />
		</div>
	);
}

export default Projects;
