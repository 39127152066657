import React from 'react'
import WidgetsDropdown from 'components/Admin/WidgetsDropdown'

const Dashboard = () => {
	return (
		<>
			<WidgetsDropdown className="mb-4" />
		</>
	)
}

export default Dashboard
