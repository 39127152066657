import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FilePreview from 'components/Admin/FilePreview';
import toast from 'react-hot-toast';
import axios from 'axios';
import Select from 'react-select';

function CategoryEdit() {
	const navigate = useNavigate();
	const { id } = useParams();
	const [file, setFile] = useState(null);
	const [categories, setCategories] = useState([]);
	const [categoriesAttr, setCategoriesAttr] = useState([]);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [category, setCategory] = useState({
		categoryName: "",
		categoryAttribute: [], 
		filePath: "",
	});

	useEffect(() => {
		const fetchCategories = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}admin/category?category_main=distinct`, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},
				});
				const data = await response.json();
				if (data.status) setCategories(data.data);
			} catch (error) {
				console.error("Error fetching categories:", error);
			}
		};

		const fetchCategoriesAttr = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}admin/categories-attr/getattr`, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},
				});
				const data = await response.json();
				if (data.status) setCategoriesAttr(data.data);
			} catch (error) {
				console.error("Error fetching category attributes:", error);
			}
		};

		fetchCategories();
		fetchCategoriesAttr();
	}, []);

	useEffect(() => {
		const fetchCategory = async () => {
			if (id) {
				try {
					const response = await fetch(`${process.env.REACT_APP_API_URL}admin/category/${id}`, {
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						},
					});
					const data = await response.json();
					if (data.status) {
						const selectedAttrIds = (data.data.categoryAttrId || '').split(',');
						const selectedAttributes = selectedAttrIds.map(attrId => {
							const matchedAttr = categoriesAttr.find(attr => attr._id === attrId);
							return matchedAttr ? { value: matchedAttr._id, label: matchedAttr.fieldName } : null;
						}).filter(Boolean);

						setCategory({
							...data.data,
							categoryAttribute: selectedAttributes,
							filePath: data.data.filePath || "",
						});
					}
				} catch (error) {
					console.error("Error fetching category details:", error);
				}
			}
		};

		fetchCategory();
	}, [id, categoriesAttr]);

	const inputHandler = (e) => {
		setCategory(prevState => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	const handleFile = (e) => setFile(e.target.files[0]);

	const handleMultiSelect = (selectedOptions) => {
		const values = selectedOptions ? selectedOptions.map(option => option) : [];
		setCategory(prevState => ({
			...prevState,
			categoryAttribute: values,
		}));
	};

	const saveCategory = async (e) => {
		e.preventDefault();
		setIsSubmitting(true);

		const formData = new FormData();
		formData.append('categoryName', category.categoryName);
		formData.append('categoryAttrId', category.categoryAttribute.map(attr => attr.value).join(','));
		if (file) formData.append('file', file);

		try {
			const url = id
				? `${process.env.REACT_APP_API_URL}admin/category/update/${id}`
				: `${process.env.REACT_APP_API_URL}admin/category/create`;
			const method = id ? 'put' : 'post';

			await axios[method](url, formData, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			});

			toast.success(`Category ${id ? 'updated' : 'added'} successfully`, { position: 'top-right' });
			navigate('/admin/category/index');
		} catch (error) {
			console.error("Error saving category:", error);
			toast.error('Error saving category');
		}
	};

	return (
		<div className='container'>
			<h1>{id ? 'Edit Category' : 'Add Category'}</h1>
			<form onSubmit={saveCategory}>
				<div className="mb-3">
					<label htmlFor="categoryName" className="form-label">Category</label>
					<input
						list="categories"
						name="categoryName"
						onChange={inputHandler}
						className="form-control"
						id="categoryName"
						value={category.categoryName}
					/>
					<datalist id="categories">
						<option value="">Select Category</option>
						{categories.map((cat) => (
							<option key={cat._id} value={cat.category}>
								{cat.name}
							</option>
						))}
					</datalist>
				</div>
				<div className="mb-3">
					<label htmlFor="categoryAttribute" className="form-label">Category Attribute</label>
					<Select
						isMulti
						name="categoryAttribute"
						options={categoriesAttr.map((attr) => ({
							value: attr._id,
							label: attr.fieldName,
						}))}
						value={category.categoryAttribute}
						onChange={handleMultiSelect}
						className="basic-multi-select"
						classNamePrefix="select"
						placeholder="Select Category Attributes"
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="file" className="form-label">File</label>
					<input
						type="file"
						name="file"
						onChange={handleFile}
						className="form-control"
						id="file"
					/>
					{category.filePath && (
						<div className="d-flex pt-2">
							<FilePreview path={category.filePath} />
						</div>
					)}
				</div>
				<button type="submit" className="btn btn-primary mb-3" disabled={isSubmitting}>{id ? 'Update' : 'Create'}</button>
			</form>
		</div>
	);
}

export default CategoryEdit;